import React from "react";
import UsersList from "./UsersList";

function UsersPage() {
  return (
    <>
      <UsersList />
    </>
  );
}

export default UsersPage;
