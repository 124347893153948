import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";
import { formatShortDate } from "../../../../utils/helper_functions";

function PaymentsDetailModule({ paymentData }) {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Header>Payment Information</Card.Header>
          <Card.Body>

            <Row>
              <Col md={4}>
                <Table>
                   <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{paymentData?.id}</td>
                    </tr>
                    <tr>
                      <th>Account</th>
                      <td>{paymentData?.account}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>{paymentData?.amount}</td>
                    </tr>
                    <tr>
                      <th>Commission</th>
                      <td>{paymentData?.commission}</td>
                    </tr>
                    <tr>
                      <th>Keyword</th>
                      <td>{paymentData?.keyword_id}</td>
                    </tr>
                   </tbody>
                </Table>
              </Col>
              <Col md={4}>
              <Table>
                   <tbody>
                    <tr>
                      <th>Narration</th>
                      <td>{paymentData?.narration}</td>
                    </tr>
                    <tr>
                      <th>Ref ID</th>
                      <td>{paymentData?.ref_id}</td>
                    </tr>
                    <tr>
                      <th>Vendor</th>
                      <td>{paymentData?.vendor_id}</td>
                    </tr>
                    <tr>
                      <th>Reconciliation</th>
                      <td>{paymentData?.is_recon ? "Yes" : "No"}</td>
                    </tr>
                   </tbody>
                </Table>
              </Col>
              <Col md={4}>
              <Table>
                   <tbody>
                    
                    <tr>
                      <th>Posted</th>
                      <td>{paymentData?.is_posted ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <th>Posted on</th>
                      <td>{formatShortDate(paymentData?.posted_date)}</td>
                    </tr>
                    <tr>
                      <th>Shortcode</th>
                      <td>{paymentData?.shortcode}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{paymentData?.status}</td>
                    </tr>
                    <tr>
                      <th>Created</th>
                      <td>{formatShortDate(paymentData?.created)}</td>
                    </tr>
                   </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default PaymentsDetailModule;
