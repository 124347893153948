import React, { useState } from "react";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import PaymentsTableList from "./widgets/PaymentsTableList";

function PaymentsPage() {
  const [filters, setFilters] = useState()
  const [formData, setFormData] = useState({
    
  });
  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilters(formData)
  }
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
        <h3>Payments</h3>
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Payments</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        <Card>
        
          <Card.Body>
            
            <Form onSubmit={handleSubmit} className="mb-1" >
            <Row className="mb-1 g-2">
              <Col md={{ span: 4, offset: 4 }}>
                <Form.Control name="account" value={formData.account} placeholder="Search phone number" onChange={handleChange} />
              </Col>
             
              <Col md={8} lg={4}>
                <ButtonGroup>
                
                <ToggleButton
                    key={1}
                    id={`radio-2`}
                    type="radio"
                    variant={'outline-success'}
                    name="status"
                    value={"Success"}
                    checked={formData.status === "Success"}
                    onChange={handleChange}
                  >
                    Success
                  </ToggleButton>
                  <ToggleButton
                    key={2}
                    id={`radio-3`}
                    type="radio"
                    variant={'outline-secondary'}
                    name="status"
                    value="Sent"
                    checked={formData.status === "Sent"}
                    onChange={handleChange}
                  >
                    Sent
                  </ToggleButton>
                  <ToggleButton
                    key={3}
                    id={`radio-4`}
                    type="radio"
                    variant={'outline-danger'}
                    name="status"
                    value="Failed"
                    checked={formData.status === "Failed"}
                    onChange={handleChange}
                  >
                    Failed
                  </ToggleButton>
                </ButtonGroup>
              </Col>
              <Col md={3} lg={2}>
              <div className="d-grid gap-2">
                <Button type="submit">Search</Button>
                </div>
              </Col>
            </Row>
            </Form>
            
            <PaymentsTableList filter={filters} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default PaymentsPage;
