import axiosAPI, { setNewHeaders, setProfileHeaders } from "../axiosApi";

export async function signUp(email, username, password) {
  const response = await axiosAPI.post("accounts/user/register", {
    email,
    username,
    password,
  });
  localStorage.setItem("user", response.data);
  return response;
}

export async function obtainToken(username, password) {
  const response = await axiosAPI.post("accounts/user/login/", {
    username,
    password,
  });
  setNewHeaders(response);
  setProfileHeaders(response);
  return response;
}

export async function refreshToken(refresh) {
  const response = await axiosAPI.post("auth/token/refresh/", {
    refresh,
  });
  setNewHeaders(response);
  return response;
}

export async function addUser(company, email, password, password2, first_name, last_name) {
  const response = await axiosAPI.post("accounts/user/register/", {
    username:email,
    email,
    password,
    password2,
    first_name,
    last_name,
    company
  });
  return response;
}

// eslint-disable-next-line
export async function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("profile");
  // TODO: invalidate token on backend
}

export const isAuthenticated = () => {
  const token = localStorage.getItem("access_token");
  return !!token;
};

export const getUserProfile = () => {
  const token = JSON.parse(localStorage.getItem("profile"));
  return token;
};

export const isAdminUser = () => {
  const profile = JSON.parse(localStorage.getItem("profile"));

  return profile?.is_staff;
};
