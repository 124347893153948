import React from "react";
import KeywordsList from "./KeywordsList";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function keywordsPage() {
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Keywords</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
            <KeywordsList />
      </Col>
    </>
  );
}

export default keywordsPage;
