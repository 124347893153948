import React, { useState } from "react";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import MessagesList from "./MessagesList";
import MessagesTableList from "./widgets/MessagesTableList";

function MessagesPage() {
  const [filters, setFilters] = useState()
  const [formData, setFormData] = useState({
    
  });
  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilters(formData)
  }
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <h3>Messages</h3>
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Messages</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        <Card>
          <Card.Body>
          <Form onSubmit={handleSubmit} className="mb-1" >
            <Row className="mb-1">
              <Col md={4}>
                <Form.Control name="phone" value={formData.phone} placeholder="Search phone number" onChange={handleChange} />
              </Col>
            </Row>
            </Form>
            <MessagesTableList filter={filters} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default MessagesPage;
