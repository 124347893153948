import axiosAPI from "../axiosApi";

export async function getAllKeyWords() {
  const response = await axiosAPI.get("content/keywords/");
  return response;
}

export async function getKeyWordsById(id) {
  const response = await axiosAPI.get(`content/keywords/` + id);
  return response;
}

export async function postKeyWords(data) {
  const response = await axiosAPI.post(`content/keywords/`, data);
  return response;
}

export async function updateKeyWords(id, data) {
  const response = await axiosAPI.put(`content/keywords/${id}/`, data);
  return response;
}

export async function deleteKeyWordsById(id) {
  const response = await axiosAPI.delete(`content/keywords/${id}`);
  return response;
}
// "activity-logs";
