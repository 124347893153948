import React, { useState } from "react";
import { Button, Form, Col, Row, FloatingLabel } from "react-bootstrap";
import {
  fetchUssdMenus,
  createUssdMenu,
  fetchUssdMenuById,
  updateUssdMenuById,
  deleteUssdMenuById
} from "../../../../services/channels/ussd-menu-service";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ShortCodeSelectList from "./ShortCodeSelectList";

function USSDMenuEditForm({ handleSubmit, ussdMenuData }) {
  const [formData, setFormData] = useState(ussdMenuData || {});
  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle switches
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleSubmit(event, formData);
    }
    setValidated(true);
  };

  const {
    isLoading,
    isError,
    error,
    data: menusData,
    isFetching,
    isPreviousData
  } = useQuery({
    queryKey: ["ussd-menus"],
    queryFn: () => fetchUssdMenus(),
    onError: (error) => {
      console.log("ussd-menus error : ", error);
    }
  });
  return (
    <Form onSubmit={handleFormSubmit} noValidate validated={validated}>
      <Row className="gx-3 gy-3">
        <Col md="12">
          <FloatingLabel controlId="floatingInputContent" label="Content">
            <Form.Control
              as="textarea"
              rows={3}
              value={formData?.content}
              name="content"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide valid content (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputMenuType" label="Menu Type">
            <Form.Select
              value={formData?.menu_type}
              name="menu_type"
              onChange={handleChange}
              required
            >
              <option value="">Select a menu type</option>
              <option value="P">Paramater</option>
              <option value="T">Tree Menu</option>
              <option value="C">Confirmation Menu</option>
              <option value="E">End</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid menu type.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputResponse" label="Response">
            <Form.Control
              type="text"
              value={formData?.response}
              name="response"
              onChange={handleChange}
              
            />
            <Form.Control.Feedback type="invalid">
              Please provide valid response (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputAction" label="Action">
            <Form.Control
              type="text"
              value={formData?.action}
              name="action"
              onChange={handleChange}
               
            />
            <Form.Control.Feedback type="invalid">
              Please provide valid action (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputValidator" label="Validator">
            <Form.Control
              type="text"
              value={formData?.validator}
              name="validator"
              onChange={handleChange}
               
            />
            <Form.Control.Feedback type="invalid">
              Please provide valid validator (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputShortcode" label="Shortcode">
            <ShortCodeSelectList
              value={formData?.shortcode}
              name="shortcode"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please select a valid processor.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputProcessor" label="Processor">
            <Form.Select
              value={formData?.processor}
              name="processor"
              onChange={handleChange}
              required
            >
              <option value="">Select a processor</option>
              <option value="TIPMaster">TIPMaster</option>
              <option value="tip processor">Tip Processor</option>
              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid processor.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputParent" label="Parent">
            <Form.Select
              value={formData?.parent}
              name="parent"
              onChange={handleChange}
              
            >
              <option value="">Select a parent</option>
              {menusData?.data?.results.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.content}
                </option>
              ))}

              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid parent.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12" className="d-grid gap-2">
          <Button variant="primary" type="submit" size="md">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default USSDMenuEditForm;
