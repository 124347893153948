import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  getAllAccountsProfile,
  getAccountsProfileById,
  postAccountsProfile,
  updateAccountsProfile,
  deleteAccountsProfileById
} from "../../../services/accounts/accounts-profile-service.js";

import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../../../services/users/users-service.js";

import ProfileDetail from "./widgets/ProfileDetail";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ProfileDetailHandler({ userId }) {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!userId) {
      navigate("/accounts");
    }
  }, [userId]);

  //get
  // const fetchAllAccountProfiles = useQuery(
  //   ["account-profiles"],
  //   getAllAccountsProfile,
  //   {
  //     onSuccess: (data) => {
  //       console.log("account-profiles : ", data);
  //     },
  //     onError: (error, variables, context) => {
  //       console.log("error is : ", error);
  //     }
  //   }
  // );

  //RetrievebyId

  const [validationErrors, setValidationErrors] = useState([]);

  const fetchUserWithProfileById = useQuery(
    ["user", userId],
    () => getUsersById(userId),
    {
      enabled: userId ? true : false,
      onSuccess: (data) => {
        console.log("users-by-id : ", data);
      },
      onError: (error) => {
        if (error.response) {
          const errorData = error.response.data;
          console.log("Validation errors:", errorData);
          setValidationErrors(Object.values(errorData).flat());
          toast.warning(
            <ul>
              {Object.values(errorData)
                .flat()
                .map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
            </ul>
          );
        } else {
          toast.error(
            "There was an error getting user data ! Please contact the administrator"
          );
        }
      }
    }
  );

  //delete
  const AccountProfilesDeleteMutation = useMutation(
    (variables) => deleteAccountsProfileById(variables),
    {
      onSuccess: (data) => {
        toast.success("Account Profile Deleted Successfully");
        queryClient.resetQueries(["account-profiles"]);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );
  return (
    <div>
      <br />
      {validationErrors.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      {!fetchUserWithProfileById.isLoading && (
        <ProfileDetail userDetail={fetchUserWithProfileById?.data?.data} />
      )}

      {fetchUserWithProfileById.isLoading && (
        <center>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      )}
    </div>
  );
}

export default ProfileDetailHandler;
