import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal,
  Image
} from "react-bootstrap";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  getAllAccountsProfile,
  getAccountsProfileById,
  postAccountsProfile,
  updateAccountsProfile,
  deleteAccountsProfileById
} from "../../../../services/accounts/accounts-profile-service.js";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

function ProfileEditForm({ profileData }) {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    phone_number: profileData?.user_profile?.phone_number
  });
  const [validationErrors, setValidationErrors] = useState([]);

  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    console.log("files xx : ", event);
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        avatar: file
      }));

      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const editProfileMutation = useMutation({
    mutationFn: (variables) =>
      updateAccountsProfile(profileData?.user_profile?.id, variables),
    onSuccess: (data) => {
      console.log("Profile Updated Successfully res : ", data);
      toast.success("Profile Updated Successfully");
      queryClient.resetQueries(["users"]);
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error updating Profile ! please contact the administrator"
        );
      }
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidationErrors([]);
    console.log("profile form data xxx  : ", formData);
    editProfileMutation.mutate(formData);

    // toast.warning("check your capital current ratio");
  };

  return (
    <>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Row className="gx-3 gy-3">
          <Col md="12">
            <FloatingLabel controlId="floatingInput" label="Phone Number">
              <Form.Control
                type="text"
                value={formData.phone_number}
                name="phone_number"
                onChange={handleChange}
                required
              />
            </FloatingLabel>
          </Col>
          <Col md="12">
            <Form.Group controlId="avatar">
              <Form.Label>Avatar</Form.Label>
              <Form.Control
                type="file"
                // accept="image/*"
                accept=".jpeg, .jpg, .png"
                onChange={handleAvatarChange}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            {previewImage && <Image src={previewImage} alt="Preview" fluid />}{" "}
            {/* Display the preview image */}
          </Col>

          <Col md="12" className="d-grid gap-2">
            <Button variant="primary" type="submit" size="md">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      {editProfileMutation.isLoading && (
        <center>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </center>
      )}
    </>
  );
}

export default ProfileEditForm;
