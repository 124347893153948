import React from "react";
import ContentList from "./ContentList";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function ContentPage() {
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Content</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        
            <ContentList />
      
      </Col>
    </>
  );
}

export default ContentPage;
