import React, { useState } from "react";
import { Button, Form, Col, Row, FloatingLabel } from "react-bootstrap";
import {
  getAllKeyWords,
  getKeyWordsById,
  postKeyWords,
  updateKeyWords,
  deleteKeyWordsById
} from "../../../services/content/keywords-service.js";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../../../services/users/users-service.js";

function KeywordsRowForm({ handleSubmit }) {
  const [formData, setFormData] = useState({
    message: "",
    parent: "",
    owner: ""
  });

  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle switches
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleSubmit(event, formData);
    }
    setValidated(true);
  };

  //get keywords
  const fetchAllKeywords = useQuery(["keywords"], getAllKeyWords, {
    onSuccess: (data) => {
      console.log("keywords : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  //get users
  //get
  const fetchAllUsers = useQuery(["users"], getAllUsers, {
    onSuccess: (data) => {
      console.log("users : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  return (
    <Form onSubmit={handleFormSubmit} noValidate validated={validated}>
      <Row className="gx-3 gy-3">
        <Col md="12">
          <FloatingLabel controlId="floatingInputKeyword" label="Keyword">
            <Form.Control
              type="text"
              value={formData?.keyword}
              name="keyword"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid keyword (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputCategory" label="Category">
            <Form.Control
              as="select"
              value={formData?.category}
              name="category"
              onChange={handleChange}
              required
            >
              <option value="">Select a Category</option>
              <option value="realtime">Realtime</option>
              {/* Add other category options here */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a valid category.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputShortcode" label="Shortcode">
            <Form.Control
              type="text"
              value={formData?.shortcode}
              name="shortcode"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid shortcode (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputPrice" label="Price">
            <Form.Control
              type="number"
              value={formData?.price}
              name="price"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid price (number).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputLabel" label="Label">
            <Form.Control
              type="text"
              value={formData?.label}
              name="label"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid label (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputCode" label="Code">
            <Form.Control
              type="number"
              value={formData?.code}
              name="code"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid code (number).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputRate" label="Rate">
            <Form.Control
              type="number"
              value={formData?.rate}
              name="rate"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid rate (string).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputMode" label="Mode">
            <Form.Select
              value={formData?.mode}
              name="mode"
              onChange={handleChange}
              required
            >
              <option value="">Select a Mode</option>
              <option value="latest">latest</option>

              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid parent.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <Form.Check
            type="switch"
            id="custom-switch-auto-reply"
            label="Auto Reply"
            name="auto_reply"
            // required
            onChange={handleChange}
            checked={formData?.auto_reply}
          />
        </Col>

        <Col md="12">
          <Form.Check
            type="switch"
            // required
            id="custom-switch-is-active"
            label="Active"
            name="is_active"
            onChange={handleChange}
            checked={formData?.is_active}
          />
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputMessage" label="Message">
            <Form.Control
              as="textarea"
              value={formData?.message}
              name="message"
              onChange={handleChange}
              // required
              maxLength={500}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid message (up to 500 characters).
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputParent" label="Parent">
            <Form.Select
              value={formData?.parent}
              name="parent"
              onChange={handleChange}
              // required
            >
              <option value="">Select a parent</option>
              {fetchAllKeywords?.data?.data?.results.map((data, index) => (
                <option key={index} value={data?.id}>
                  {data?.keyword}
                </option>
              ))}
              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid parent.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputOwner" label="Owner">
            <Form.Select
              value={formData?.owner}
              name="owner"
              onChange={handleChange}
              // required
            >
              <option value="">Select Owner</option>
              {fetchAllUsers?.data?.data?.results.map((data, index) => (
                <option key={index} value={data?.id}>
                  {data?.username}
                </option>
              ))}
              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid parent.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12" className="d-grid gap-2">
          <Button variant="primary" type="submit" size="md">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default KeywordsRowForm;
