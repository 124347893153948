import React, { useState } from "react";

import DashboardPage from "./components/dashboard/DashboardPage";
import ContentPage from "./components/content/ContentPage";
// import PaymentsPage from "./components/payments/PaymentsPage";
import WalletPage from "./components/wallet/WalletPage";
import AccountsPage from "./components/accounts/AccountsPage";
import UserProfile from "./components/accounts/profiles/ProfilesPage.js";
import MessagesPage from "./components/messages/MessagesPage.js";
import KeywordsPage from "./components/keywords/KeywordsPage";
import KeywordsDetailsPage from "./components/keywords/KeywordsDetailsPage.js";
import FinancePage from "./components/finance/FinancePage";
import PaymentsPage from "./components/finance/payments/PaymentsPage";
import PaymentsDetailsPage from "./components/finance/payments/PaymentDetailsPage.js";
import MessageDetailsPage from "./components/messages/MessagesDetailsPage.js";
import USSDMenuPage from "./components/channels/ussdmenu/USSDMenuPage";
import USSDMenuDetailsPage from "./components/channels/ussdmenu/UssdMenueDetailsPage.js";
import USSDSessionPage from "./components/channels/ussdsession/USSDSessionPage";
import USSDSessionDetailsPage from "./components/channels/ussdsession/UssdSessionsDetailsPage.js";
import SettingsPage from "./components/settings/SettingsPage.js";
import WalletPocketDetailsPage from "./components/wallet/WalletPocketDetailsPage.js";
import DashboardWalletsPocketDetailModule from "./components/wallet/widgets/DashboardWalletsPocketDetailModule";
import ContactsPage from "./components/messages/ContactsPage";
import ContactDetailPage from "./components/messages/ContactDetailPage";
import OutBlastsPage from "./components/messages/OutBlastsPage";
// import OutBoxPage from "./components/outbox/OutBoxPage.js";

//
function AppRoutes() {
  let privateDefaultRoutes = [
    {
      path: "",
      name: "dashbord",
      element: DashboardPage,
      layout: "/private"
    },
    {
      path: "content",
      name: "content",
      element: ContentPage,
      layout: "/private"
    },

    {
      path: "wallet",
      name: "wallet",
      element: WalletPage,
      layout: "/private"
    },
    {
      path: "accounts",
      name: "accounts",
      element: AccountsPage,
      layout: "/private"
    },
    {
      path: "accounts/profile",
      name: "accounts/profile",
      element: UserProfile,
      layout: "/private"
    },
    {
      path: "messages",
      name: "messages",
      element: MessagesPage,
      layout: "/private"
    },
    {
      path: "messages/detail",
      name: "messages/detail",
      element: MessageDetailsPage,
      layout: "/private"
    },
    {
      path: "keywords",
      name: "keywords",
      element: KeywordsPage,
      layout: "/private"
    },
    {
      path: "keywords/detail",
      name: "keywords/detail",
      element: KeywordsDetailsPage,
      layout: "/private"
    },

    {
      path: "finance",
      name: "finance",
      element: FinancePage,
      layout: "/private"
    },
    {
      path: "payments",
      name: "payments",
      element: PaymentsPage,
      layout: "/private"
    },
    {
      path: "payments/detail",
      name: "payments/detail",
      element: PaymentsDetailsPage,
      layout: "/private"
    },
    {
      path: "channels/sessions",
      name: "sessions",
      element: USSDSessionPage,
      layout: "/private"
    },
    {
      path: "channels/sessions/detail",
      name: "channels/sessions/detail",
      element: USSDSessionDetailsPage,
      layout: "/private"
    },
    {
      path: "channels/menus",
      name: "menus",
      element: USSDMenuPage,
      layout: "/private"
    },
    {
      path: "channels/menus/detail",
      name: "channels/menus/detail",
      element: USSDMenuDetailsPage,
      layout: "/private"
    },
    {
      path: "wallet/detail",
      name: "wallet/detail",
      element: WalletPocketDetailsPage,
      layout: "/private"
    },
    {
      path: "pocket/detail",
      name: "pocket/detail",
      element: DashboardWalletsPocketDetailModule,
      layout: "/private"
    },
    {
      path: "settings",
      name: "settings",
      element: SettingsPage,
      layout: "/private"
    },
    {
      path: "broadcasts",
      name: "broadcasts",
      element: ContactsPage,
      layout: "/private"
    },
    {
      path: "broadcasts/:id",
      name: "broadcasts",
      element: ContactDetailPage,
      layout: "/private"
    },
    {
      path: "blasts",
      name: "blasts",
      element: OutBlastsPage,
      layout: "/private"
    }
    // {
    //   path: "/outbox",
    //   name: "outbox",
    //   element: OutBoxPage,
    //   layout: "/private"
    // }
  ];
  const [privateRoutes, setPrivateRoutes] = useState(privateDefaultRoutes);

  return privateRoutes;
}

export default AppRoutes;
