import React, { useState } from 'react'
import { Alert, Breadcrumb, Button, Card, Col, Row, Table } from 'react-bootstrap'
import { approveContactById, fetchContactById } from '../../services/marketing/contacts-services';
import { formatDate, formatHttpErrorMessage, formatShortDate } from '../../utils/helper_functions';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';

function ContactDetailPage(props) {
    let {id} = useParams()
    const [message, setMessage] = useState();
    const { isLoading, isError, error, data:contact, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["contact-list", id],
      queryFn: () => fetchContactById(id),
      keepPreviousData: true
    });

    const approveSelectedMessage = async () => {
        if(!window.confirm("Are you sure you want to send this message")){
            return;
        }
        await approveContactById(id).then(response => {
            alert("Item approved and scheduled")
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg)
        })
    }
  return (
    <>
    <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/broadcasts" }}>
              Broadcasts
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{id}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col md={12}>
            <Button onClick={() => approveSelectedMessage()} className='float-end' disabled={contact?.data.status !='Draft'}>Approved</Button>
        </Col>
        
        {message && <Alert variant='danger' onClose={() => setMessage(false)} dismissible>{message}</Alert>}
      </Row>
        <Row>
            <Col md={12}>
                <Card>
                <Card.Body>
                {isLoading ? (<>Loading</>) : isError ? (
            <Alert variant='danger'>{error.message}</Alert>
          ) : (<>
                    <Row>
                        <Col md={6}>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Send date</th>
                                        <td>{formatShortDate(contact.data.send_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>Expiry date</th>
                                        <td>{formatShortDate(contact.data.expiry_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>File</th>
                                        <td>{contact.data.file.substring(contact.data.file.lastIndexOf('/')+1)}</td>
                                    </tr>
                                    <tr>
                                        <th>Records</th>
                                        <td>{contact.data.records}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table>
                                <tbody>
                                    
                                    <tr>
                                        <th>Status</th>
                                        <td>{contact.data.status}</td>
                                    </tr>
                                    <tr>
                                        <th>Author</th>
                                        <td>{contact.data.author}</td>
                                    </tr>
                                    <tr>
                                        <th>Updated</th>
                                        <td>{formatShortDate(contact.data.updated)}</td>
                                    </tr>
                                    <tr>
                                        <th>Created</th>
                                        <td>{formatShortDate(contact.data.created)}</td>
                                    </tr>
                                    <tr>
                                        <th>Error</th>
                                        <td>{ contact.data.resp_msg}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={12}>
                            <fieldset>
                                <legend>Message</legend>
                                {contact.data.message}
                            </fieldset>
                        </Col>
                    </Row></>)}
                </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default ContactDetailPage