import React, { useState } from "react";

import {
  fetchUssdMenus,
  createUssdMenu,
  fetchUssdMenuById,
  updateUssdMenuById,
  deleteUssdMenuById
} from "../../../../services/channels/ussd-menu-service";

import { Link } from "react-router-dom";
import {
  Check2Circle,
  CloudCheck,
  CloudCheckFill,
  XCircleFill,
  PencilSquare,
  Trash
} from "react-bootstrap-icons";
import { Button, Col, Row, Spinner, Table, Alert } from "react-bootstrap";

import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import UssdMenuEditFormModal from "../UssdMenuEditFormModal";
import moment from "moment";

function USSDMenuList() {
  const queryClient = useQueryClient();
  const {
    isLoading,
    isError,
    error,
    data: menus,
    isFetching,
    isPreviousData
  } = useQuery({
    queryKey: ["ussd-menus"],
    queryFn: () => fetchUssdMenus(),
    onError: (error) => {
      console.log("ussd-menus error : ", error);
    }
  });

  //edit form

  const [selectedRowData, setSelectedRowData] = useState();
  const [showEditForm, setShowEditForm] = useState(false);

  const handleCloseEditForm = () => {
    setSelectedRowData({ id: null });
    setShowEditForm(false);
  };

  const handleEditTableRow = (rowData) => {
    setSelectedRowData(rowData);
    setShowEditForm(true);
    console.log("SelectedRowData is : ", rowData);
  };

  //delete
  const UssdMenuDeleteMutation = useMutation(
    (variables) => deleteUssdMenuById(variables),
    {
      onSuccess: (data) => {
        toast.success("Ussd Deleted Successfully");
        queryClient.resetQueries(["ussd-menus"]);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  // State for delete confirmation dialog
  const [selectedRowDataToDelete, setSelectedRowDataToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Handler to open delete confirmation dialog
  const handleDeleteTableRow = (rowData) => {
    console.log("Handle click table row");
    setSelectedRowData(rowData);
    setOpenDeleteDialog(true);
  };

  // Handler to close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setSelectedRowData(null);
    setOpenDeleteDialog(false);
  };

  // Handler to confirm delete
  const handleConfirmDelete = (rowData) => {
    if (rowData) {
      UssdMenuDeleteMutation.mutate(rowData.id);
    }
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            {/* <td>id</td> */}
            <td>Content</td>
            <td>MenuType</td>
            <td>Processor</td>
            <td>Parent</td>
            <td>Date</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            <Alert variant="danger">{error.message}</Alert>
          ) : (
            <>
              {menus.data.results.map((item, i) => (
                <tr>
                   <th><Link
                      to="/channels/menus/detail"
                      state={{ ussdmenuId: item?.id }}
                      className="text-decoration-none"
                    >{item?.id}</Link></th>
                  <td> {item.content}</td>
                  <td>{item.menu_type}</td>
                  <td>{item.processor}</td>
                  <td>{item.parent}</td>
                  <td>{moment(item?.created).format("YYYY-MM-DD HH:mm:ss")}</td>
                  <td>
                    <PencilSquare
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                        color: isLoading ? "gray" : "blue",
                        opacity: isLoading ? 0.5 : 1,
                        marginRight: "1rem"
                      }}
                      onClick={
                        isLoading ? null : () => handleEditTableRow(item)
                      }
                    />

                    <Trash
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                        color: isLoading ? "gray" : "red",
                        opacity: isLoading ? 0.5 : 1
                      }}
                      onClick={
                        isLoading ? null : () => handleDeleteTableRow(item)
                      }
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
      <UssdMenuEditFormModal
        ussdMenuData={selectedRowData}
        show={showEditForm}
        onHide={handleCloseEditForm}
        onClose={handleCloseEditForm}
      />

      {/* Confirmation Dialog for Deleting */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Ussd Menu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(selectedRowData);
              handleCloseDeleteDialog();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default USSDMenuList;
