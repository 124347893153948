import axiosAPI from "../axiosApi";

export async function fetchAllContacts() {
  const response = await axiosAPI.get("marketing/contacts/");
  return response;
}

export async function fetchContactById(id) {
  const response = await axiosAPI.get(`marketing/contacts/` + id);
  return response;
}

export async function postContact(data) {
  axiosAPI.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  const response = await axiosAPI.post(`marketing/contacts/`, data, {headers:{
    'Content-Type': 'multipart/form-data'
  }});
  return response;
}

export async function updateContact(id, data) {
  const response = await axiosAPI.put(`marketing/contacts/${id}/`, data);
  return response;
}

export async function approveContactById(id) {
    const response = await axiosAPI.get(`marketing/contacts/${id}/approve/`);
    return response;
  }

export async function deleteMessagesById(id) {
  const response = await axiosAPI.delete(`marketing/contacts/${id}`);
  return response;
}