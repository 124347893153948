import React, { useState } from 'react'
import { fetchAllOutBlasts } from '../../../services/marketing/outblast-services';
import { useQuery } from '@tanstack/react-query';
import { Alert, Spinner, Table } from 'react-bootstrap';
import { formatNumber, formatShortDate, shortText } from '../../../utils/helper_functions';
import Pager from '../../common/Pager';
import StatusBadge from '../../common/StatusWidget';

function OutBlastTableList(params) {
    const [page, setPage] = useState(1)
    const { isLoading, isError, error, data:messages, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["outblasts",page],
      queryFn: () => fetchAllOutBlasts({page:page}),
      keepPreviousData: true
    });
    const setCurrentPage = (e, currentPage) =>{
        
        setPage(prevState => currentPage)
    }

  return (
    <div>
      {isLoading ? (
        <Spinner />
          ) : isError ? (
            <Alert variant="danger">Error: {error.message}</Alert>
          ) : (<>
           <p>About {formatNumber(messages.data.count)} results</p>
            <Table bordered striped responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Phone</th>
                        <th>Message</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.data.results.map((item, i) => (
                        <tr key={i}>
                        <td>{item.id}</td>
                        <td>{item.phone}</td>
                        <td>{shortText(item.message,60)}</td>
                        <td>{item.category}</td>
                        <td><StatusBadge value={item.status} /></td>
                        <td>{formatShortDate(item.created)}</td>
                    </tr>
                    ))}
                </tbody>
            </Table>
            <Pager numberOfPages={Math.floor(messages.data.count/50)} activePage={page} goToPage={setCurrentPage} />
          </>)} 
    </div>
  )
}

export default OutBlastTableList