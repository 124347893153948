import axiosAPI from "../axiosApi";

//=======================  finance transactions =============================

export async function getAllFinanceTransactions() {
  const response = await axiosAPI.get("finance/transactions/");
  return response;
}

export async function getAllFinanceTransactionsById(id) {
  const response = await axiosAPI.get(`finance/transactions/${id}/`);
  return response;
}

export async function postFinanceTransactionsWithdraw(data) {
  const response = await axiosAPI.post(`finance/transactions/withdraw/`, data);
  return response;
}
