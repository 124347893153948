import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import {
  getAllKeyWords,
  getKeyWordsById,
  postKeyWords,
  updateKeyWords,
  deleteKeyWordsById
} from "../../services/content/keywords-service.js";
import KeywordsRowForm from "./widgets/KeywordsRowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

function KeywordsCreateFormModal(props) {
  const queryClient = useQueryClient();
  const [validationErrors, setValidationErrors] = useState([]);

  const creactKeywordMutation = useMutation(postKeyWords, {
    onSuccess: () => {
      queryClient.resetQueries(["keywords"]);
      props.onClose();
      // queryClient.invalidateQueries(["projects"]);
      toast.success("Keyword Created Successfully");
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error creating key word ! Please contact the administrator"
        );
      }
    }
  });

  const handleSubmit = async (event, data) => {
    event.preventDefault();
    setValidationErrors([]); // Reset validation errors
    console.log("data we are submitting vvvv : ", data);
    creactKeywordMutation.mutate(data);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Key word Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Fill in the form below</p>
        <KeywordsRowForm handleSubmit={handleSubmit} />
        <br />
        {validationErrors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* <h4>{creactContentMutation.status}</h4> */}

        {creactKeywordMutation.isLoading && (
          <center>
            {" "}
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default KeywordsCreateFormModal;
