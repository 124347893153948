import React from 'react'
import { Form } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { fetchUSSDShortcodes } from '../../../../services/channels/shortcode-service';

function ShortCodeSelectList(props) {
    const { isLoading, isError, error, data:items, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["shortcodes"],
      queryFn: () => fetchUSSDShortcodes({}),
      keepPreviousData: true
    });

  return (
    <div>
        {isLoading ? (<>Loading</>) : isError ? (
             <div>Error: {error.message}</div>
          ) : (
        <Form.Select aria-label="Default select code"  {...props} >
                    <option>Select</option>
                    {items.data.results.map((item, i) => (
                        <option value={item.id} key={i}>{item.code} </option>
                    ))}
                </Form.Select>
          )}
    </div>
  )
}

export default ShortCodeSelectList