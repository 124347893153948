import React from "react";
import WalletList from "./WalletList";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function WalletPage({ authUserProfile }) {
  console.log("authUserProfile on wallet : ", authUserProfile);
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <h3>Wallet Accounts</h3>
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Wallet</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        <WalletList authUserProfile={authUserProfile} />
      </Col>
    </>
  );
}

export default WalletPage;
