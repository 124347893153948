import React from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
      <>
          <center>
            <h1>404: There is nothing here</h1>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
          </center>
        
      </>
    );}

export default PageNotFound;
