import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";

function MessagesDetailModule({ messageData }) {
  return (
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Message Information</Card.Header>
            <Card.Body>
            <dt>Message</dt>
            {messageData?.message}
            </Card.Body>
            <Card.Body>
         
              <Row>
                <Col md={4}>
                
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">ID</th>
                        <td>{messageData?.id}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Phone</th>
                        <td>{messageData?.phone}</td>
                      </tr>
                     
                      <tr>
                        <th className="text-nowrap">Expiry</th>
                        <td>
                          {moment(messageData?.expiry).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Content ID</th>
                        <td>{messageData?.content_id}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Status</th>
                        <td>{messageData?.status}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Vendor</th>
                        <td>{messageData?.vendor}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Processed</th>
                        <td>
                          {moment(messageData?.processed).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      
                      <tr>
                        <th className="text-nowrap">Created At</th>
                        <td>
                          {moment(messageData?.created).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Keyword</th>
                        <td>{messageData?.keyword}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Owner</th>
                        <td>{messageData?.owner}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Updated At</th>
                        <td>
                          {moment(messageData?.updated).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  );
}

export default MessagesDetailModule;
