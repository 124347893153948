import axiosAPI from "../axiosApi";

export async function getAllUsers() {
  const response = await axiosAPI.get("accounts/user/");
  return response;
}

export async function getUsersById(id) {
  const response = await axiosAPI.get(`accounts/user/` + id);
  return response;
}

export async function postUsers(data) {
  const response = await axiosAPI.post(`accounts/user/`, data);
  return response;
}

export async function updateUsers(id, data) {
  const response = await axiosAPI.put(`accounts/user/${id}/`, data);
  return response;
}

export async function deleteUsersById(id) {
  const response = await axiosAPI.delete(`accounts/user/${id}`);
  return response;
}
// "activity-logs";

export async function changePasswordPost(data) {
  const response = await axiosAPI.post(`accounts/user/change_password/`, data);
  return response;
}
