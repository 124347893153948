import React, { useState } from "react";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import ProfileDetailHandler from "./ProfileDetailHandler";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function ProfilesPage() {
  //
  const { state } = useLocation();
  let userId = state?.userId;
  //
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/accounts" }}>
              Accounts
            </Breadcrumb.Item>
            <Breadcrumb.Item active>profile</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <ProfileDetailHandler userId={userId} />
      </Row>
    </>
  );
}

export default ProfilesPage;
