import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../../../services/users/users-service.js";
import UsersEditForm from "./widgets/UsersEditForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

function UsersEditFormModal({ userData, ...props }) {
  const [validationErrors, setValidationErrors] = useState([]);
  const queryClient = useQueryClient();

  const editUsersMutation = useMutation({
    mutationFn: (variables) => updateUsers(userData?.id, variables),
    onSuccess: () => {
      props.onClose();
      toast.success("User Updated Successfully");
      queryClient.resetQueries(["users"]);
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error updating user ! please contact the administrator"
        );
      }
    }
  });

  const handleSubmit = (event, data) => {
    console.log(data);
    event.preventDefault();
    setValidationErrors([]);

    editUsersMutation.mutate(data);

    // toast.warning("check your capital current ratio");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Users Edit Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationErrors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        <p>Fill in the form below</p>

        <UsersEditForm userData={userData} handleSubmit={handleSubmit} />
        {/* <p>{editProjectsMutation.status}</p> */}
        {editUsersMutation.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UsersEditFormModal;
