import React from "react";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function FinancePage() {
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Finance</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        <Card>
          <Card.Body>
            <div>Finance</div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default FinancePage;
