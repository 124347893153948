import axiosAPI from "../axiosApi";

export async function fetchUSSDShortcodes() {
  const response = await axiosAPI.get("channels/ussd/shortcodes/");
  return response;
}

export async function createUSSDShortcode(payload) {
  const response = await axiosAPI.post("channels/ussd/shortcodes/", payload);
  return response;
}

export async function fetchUSSDShortcodeById(id) {
  const response = await axiosAPI.get(`channels/ussd/shortcodes/${id}/`);
  return response;
}

export async function updateUSSDShortcodeById(id, payload) {
  const response = await axiosAPI.put(`channels/ussd/shortcodes/${id}/`, payload);
  return response;
}

export async function deleteUSSDShortcodeById(id) {
  const response = await axiosAPI.delete(`channels/ussd/shortcodes/${id}`);
  return response;
}