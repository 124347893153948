import axiosAPI from "../axiosApi";

export async function getAllContent() {
  const response = await axiosAPI.get("content/content/");
  return response;
}

export async function getContentById(id) {
  const response = await axiosAPI.get(`content/content/` + id);
  return response;
}

export async function postContent(data) {
  const response = await axiosAPI.post(`content/content/`, data);
  return response;
}

export async function updateContent(id, data) {
  const response = await axiosAPI.put(`content/content/${id}/`, data);
  return response;
}

export async function deleteContentById(id) {
  const response = await axiosAPI.delete(`content/content/${id}`);
  return response;
}
// "activity-logs";
