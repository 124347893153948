import React, { useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { postContact } from '../../../services/marketing/contacts-services';
import { formatHttpErrorMessage } from '../../../utils/helper_functions';
import { useNavigate } from 'react-router-dom';

function ContactForm(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState();
    let navigate = useNavigate()
    const [formData, setFormData] = useState({})

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
      if (e.target.files) {
        setSelectedFile(e.target.files[0]);
      }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if ( typeof selectedFile === 'undefined' ) return;
        console.log(selectedFile)
        const myformData = new FormData();
        myformData.append("message", formData.message);
        myformData.append("send_date", formData.send_date);
        myformData.append("expiry_date", formData.expiry_date);
        myformData.append("file", selectedFile);
        await postContact(myformData).then(response => {
          navigate(`/broadcasts/${response.data.id}`)
            props.onSuccess()
        }).catch(error => {
            let msg = formatHttpErrorMessage(error);
            setMessage(msg)
        })
    }
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" >
        Member Form {props.id}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Form onSubmit={handleSubmit}>
    {message && <Alert variant='danger'>{message}</Alert>}
    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formName">
                    <Form.Label>Message</Form.Label>
                    <Form.Control type="text" as="textarea" rows={3} name="message" value={formData.message} onChange={handleChange} />
                    </Form.Group>
        </Row>
        <Row className="mb-3">
                    <Form.Group as={Col} controlId="formProductPremium">
                    <Form.Label>Send Date</Form.Label>
                    <Form.Control type="datetime-local" name="send_date" value={formData.send_date} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formProductBPremium">
                    <Form.Label>Expiry</Form.Label>
                    <Form.Control type="datetime-local" name="expiry_date" value={formData.expiry_date} onChange={handleChange}/>
                    </Form.Group>
        </Row>
        <Row className="mb-3">
                    <Form.Group as={Col} controlId="formName">
                    <Form.Label>Contact File</Form.Label>
                    <input type="file" name="file"  onChange={handleFileChange} />
                    </Form.Group>
        </Row>
        <Row>
                <Col md={12}>
                    <Button variant="primary" className='float-end' type="submit">
                        Save Message
                    </Button>
                </Col>
            </Row>
    </Form>
    </Modal.Body>
    </Modal>
  )
}

export default ContactForm