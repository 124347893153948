import axiosAPI from "../axiosApi";

export async function getAllMessages(params={}) {
  const response = await axiosAPI.get("content/messages/", {params:params});
  return response;
}

export async function getMessagesById(id) {
  const response = await axiosAPI.get(`content/messages/` + id);
  return response;
}

export async function postMessages(data) {
  const response = await axiosAPI.post(`content/messages/`, data);
  return response;
}

export async function updateMessages(id, data) {
  const response = await axiosAPI.put(`content/messages/${id}/`, data);
  return response;
}

export async function deleteMessagesById(id) {
  const response = await axiosAPI.delete(`content/messages/${id}`);
  return response;
}
// "activity-logs";
