import axiosAPI from "../axiosApi";

export async function getAllFinancePocket() {
  const response = await axiosAPI.get("finance/pockets/");
  return response;
}

export async function getAllFinancePocketById(id) {
  const response = await axiosAPI.get(`finance/pockets/${id}`);
  return response;
}

export async function getAllFinancePocketByIdMinStatement(id) {
  const response = await axiosAPI.get(`finance/pockets/${id}/min_statement/`);
  return response;
}

export async function getAllFinancePocketPrimary() {
  const response = await axiosAPI.get("finance/pockets/primary/");
  return response;
}
