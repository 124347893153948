import React from "react";
import { useNavigate } from 'react-router-dom';
import {Button} from 'react-bootstrap'
import { logout } from "../../services/auth/auth-service";

function NotAuthorised() {
    const navigate = useNavigate();
    const logoutUser = async () => {
      if (window.confirm("Are you sure you want to logout?")) {
        await logout();
        //navigate("/login", { replace: true });
        window.location.reload(true);
      }
    };
  return (
          <center className="mt-5">
            <h1>403: You are not authorised to access this feature</h1>
            <Button variant="primary" onClick={() => navigate(-1)}>Go Back</Button> &nbsp;
            <Button variant="outline-primary" onClick={() => navigate('/')}>Go to Dashboard</Button> &nbsp;
            <Button variant="info" onClick={() => logoutUser()}>Logout again</Button>
          </center>
  )
}

export default NotAuthorised