import axiosAPI from "../axiosApi";

export async function getAllFinancePayments(params={}) {
  const response = await axiosAPI.get("finance/payments/", {params:params});
  return response;
}

export async function getFinancePaymentsById(id) {
  const response = await axiosAPI.get(`finance/payments/` + id);
  return response;
}

export async function postFinancePayments(data) {
  const response = await axiosAPI.post(`finance/payments/`, data);
  return response;
}

export async function updateFinancePayments(id, data) {
  const response = await axiosAPI.put(`finance/payments/${id}/`, data);
  return response;
}

export async function deleteFinancePaymentsById(id) {
  const response = await axiosAPI.delete(`finance/payments/${id}`);
  return response;
}
// "activity-logs";

// ==============     other finance routes ==========================

export async function getAllHitsSummary() {
  const response = await axiosAPI.get("finance/payments/hits_summary/");
  return response;
}

export async function getHotKeywords() {
  const response = await axiosAPI.get("finance/payments/hot_keywords/");
  return response;
}

export async function getMonthlyHitsSummary(params={}) {
  const response = await axiosAPI.get("finance/payments/monthly_hits/");
  return response;
}

export async function getWeeklyHitsSummary(params={}) {
  const response = await axiosAPI.get("finance/payments/weekly_hits/");
  return response;
}