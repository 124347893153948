import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";

function UssdSessionsDetailModule({ ussdSessionsData }) {
  return (
    <Container className="mt-5">
      {/* <h2>Sessions Details</h2> */}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Ussd Sessions Details</Card.Header>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">ID</th>
                        <td>{ussdSessionsData?.id}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Phone</th>
                        <td>{ussdSessionsData?.phone}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Message</th>
                        <td>{ussdSessionsData?.message}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Expiry</th>
                        <td>
                          {moment(ussdSessionsData?.expiry).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Content ID</th>
                        <td>{ussdSessionsData?.content_id}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Status</th>
                        <td>{ussdSessionsData?.status}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Vendor</th>
                        <td>{ussdSessionsData?.vendor}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Processed</th>
                        <td>
                          {moment(ussdSessionsData?.processed).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Updated At</th>
                        <td>
                          {moment(ussdSessionsData?.updated).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Created At</th>
                        <td>
                          {moment(ussdSessionsData?.created).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Keyword</th>
                        <td>{ussdSessionsData?.keyword}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Owner</th>
                        <td>{ussdSessionsData?.owner}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UssdSessionsDetailModule;
