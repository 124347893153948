import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import {
  getAllContent,
  getContentById,
  postContent,
  updateContent,
  deleteContentById
} from "../../services/content/content-service.js";

import {
  getAllKeyWords,
  getKeyWordsById,
  postKeyWords,
  updateKeyWords,
  deleteKeyWordsById
} from "../../services/content/keywords-service.js";
import KeywordsEditForm from "./widgets/KeywordsEditForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

function KeywordsEditFormModal({ keywordsData, ...props }) {
  const queryClient = useQueryClient();
  const [validationErrors, setValidationErrors] = useState([]);

  const editKeywordsMutation = useMutation({
    mutationFn: (variables) => updateKeyWords(keywordsData?.id, variables),
    onSuccess: () => {
      queryClient.resetQueries(["keywords"]);
      props.onClose();
      toast.success("Keywords Updated Successfully");
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error updating key word ! Please contact the administrator"
        );
      }
    }
  });

  const handleSubmit = (event, data) => {
    console.log(data);
    event.preventDefault();
    setValidationErrors([]);

    editKeywordsMutation.mutate(data);

    // toast.warning("check your capital current ratio");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Keywords Edit Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Fill in the form below</p>

        <KeywordsEditForm
          keywordsData={keywordsData}
          handleSubmit={handleSubmit}
        />
        <br />
        {validationErrors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* <p>{editProjectsMutation.status}</p> */}
        {editKeywordsMutation.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default KeywordsEditFormModal;
