import React, { useState } from "react";
import {
  getAllFinancePocket,
  getAllFinancePocketById,
  getAllFinancePocketByIdMinStatement,
  getAllFinancePocketPrimary
} from "../../services/finance/pockets-service.js";
import WalletsTableList from "./widgets/WalletsTableList";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Row, Col, Card } from "react-bootstrap";
import WalletsPocketDetailModule from "./widgets/WalletsPocketDetailModule";
import { Button, Spinner, Table } from "react-bootstrap";

function WalletList({ authUserProfile }) {
  const queryClient = useQueryClient();

  //get
  const fetchAllFinancePocket = useQuery(["pockets"], getAllFinancePocket, {
    onSuccess: (data) => {
      console.log("pockets  : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  //Get Non Admin Pocket
  //get
  const fetchFinancePocketPrimaryQuery = useQuery(
    ["pockets", "primary"],
    getAllFinancePocketPrimary,
    {
      onSuccess: (data) => {
        console.log("pockets primary : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <Col>
      {authUserProfile?.is_staff ? (
        <Col>
          <Card>
            <Card.Header>
              <h5>Pocket List</h5>{" "}
              {/* Replace with your desired header content */}
            </Card.Header>
            <Card.Body>
              <WalletsTableList
                tableData={fetchAllFinancePocket}
                // handleEditTableRow={handleEditTableRow}
                // handleDeleteTableRow={handleDeleteTableRow}
                isLoading={fetchAllFinancePocket.isLoading}
              />
            </Card.Body>
          </Card>{" "}
        </Col>
      ) : (
        <WalletsPocketDetailModule
          poketsDataQuery={fetchFinancePocketPrimaryQuery}
        />
      )}
    </Col>
  );
}

export default WalletList;
