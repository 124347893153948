import React from 'react'
import { fetchAllContacts } from '../../../services/marketing/contacts-services';
import { Table } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { formatShortDate, shortText } from '../../../utils/helper_functions';
import { Link } from 'react-router-dom';
import StatusBadge from '../../common/StatusWidget';

function ContactTableList() {
    const { isLoading, isError, error, data:contacts, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["contact-list"],
      queryFn: () => fetchAllContacts({}),
      keepPreviousData: true
    });
  return (
    <div>
        {isLoading ? (<>Loading</>) : isError ? (
            <p>Error: {error.message}</p>
          ) : (<>
             <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Message</th>
                        <th>Send date</th>
                        <th>Expiry date</th>
                        <th>Records</th>
                        <th>Status</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {contacts.data.results.map((item, i) =>(
                         <tr key={i}>
                          <td><Link to={`${item.id}`}>{item.id}</Link></td>
                         <td>{shortText(item.message,40)}</td>
                         <td>{formatShortDate(item.send_date)}</td>
                         <td>{formatShortDate(item.expiry_date)}</td>
                         <td>{item.records}</td>
                         <td><StatusBadge value={item.status} /></td>
                         
                     </tr>
                    ))}
                </tbody>
             </Table>
          </>)}
    </div>
  )
}

export default ContactTableList