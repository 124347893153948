import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../../../services/users/users-service.js";
import UsersRowForm from "./widgets/UsersRowForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

function UsersCreateFormModal(props) {
  const queryClient = useQueryClient();
  const [validationErrors, setValidationErrors] = useState([]);

  const creactUsersMutation = useMutation(postUsers, {
    onSuccess: () => {
      queryClient.resetQueries(["users"]);
      // queryClient.invalidateQueries(["projects"]);
      props.onClose();
      toast.success("User Created Successfully");
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);
        setValidationErrors(Object.values(errorData).flat());
        toast.warning(
          <ul>
            {Object.values(errorData)
              .flat()
              .map((error, index) => (
                <li key={index}>{error}</li>
              ))}
          </ul>
        );
      } else {
        if (error.response) {
          const errorData = error.response.data;
          console.log("Validation errors:", errorData);

          const testvalidationErrors = Object.entries(errorData).map(
            ([key, value]) => ({
              key,
              value: value.join(", ") // Convert the array of error messages to a string
            })
          );

          setValidationErrors(testvalidationErrors);

          // Create the alert message
          const alertMessage = (
            <ul>
              {testvalidationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          );

          // Show the alert using your preferred alert mechanism (e.g., toast)
          toast.warning(alertMessage);
        } else {
          toast.error(
            "There was an error creating user! Please contact the administrator"
          );
        }
      }
    }
  });

  const handleSubmit = async (event, data) => {
    event.preventDefault();
    setValidationErrors([]); // Reset validation errors
    console.log("data we are submitting : ", data);
    creactUsersMutation.mutate(data);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Users Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Fill in the form below</p>
        {validationErrors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        <UsersRowForm handleSubmit={handleSubmit} />
        <br />

        {/* <h4>{creactusersMutation.status}</h4> */}

        {creactUsersMutation.isLoading && (
          <center>
            {" "}
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UsersCreateFormModal;
