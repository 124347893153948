import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getAllContent,
  getContentById,
  postContent,
  updateContent,
  deleteContentById
} from "../../services/content/content-service.js";

import ContentTableList from "./widgets/ContentTableList";
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import ContentCreateFormModal from "./ContentCreateFormModal.jsx";
import ContentEditFormModal from "./ContentEditFormModal.jsx";

//
import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function ContentList() {
  const queryClient = useQueryClient();

  //get
  const fetchAllContent = useQuery(["content"], getAllContent, {
    onSuccess: (data) => {
      console.log("content : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  //delete
  const ContentDeleteMutation = useMutation(
    (variables) => deleteContentById(variables),
    {
      onSuccess: (data) => {
        toast.success("Content Deleted Successfully");
        queryClient.resetQueries(["content"]);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  const [showAddForm, setShowAddForm] = useState(false);
  const onFormClose = () => {
    setShowAddForm(false);
  };

  //edit form

  const [selectedRowData, setSelectedRowData] = useState();
  const [showEditForm, setShowEditForm] = useState(false);

  const handleCloseEditForm = () => {
    setSelectedRowData({ id: null });
    setShowEditForm(false);
  };

  const handleEditTableRow = (rowData) => {
    setSelectedRowData(rowData);
    setShowEditForm(true);
    console.log("SelectedRowData is : ", rowData);
  };

  //delete

  // const handleDeleteTableRow = (rowData) => {
  //   UsersDeleteMutation.mutate(rowData?.id);
  // };

  // State for delete confirmation dialog
  const [selectedRowDataToDelete, setSelectedRowDataToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Handler to open delete confirmation dialog
  const handleDeleteTableRow = (rowData) => {
    console.log("Handle click table row");
    setSelectedRowData(rowData);
    setOpenDeleteDialog(true);
  };

  // Handler to close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setSelectedRowData(null);
    setOpenDeleteDialog(false);
  };

  // Handler to confirm delete
  const handleConfirmDelete = (rowData) => {
    if (rowData) {
      ContentDeleteMutation.mutate(rowData.id);
    }
  };

  //
  return (
    <div>
      <Col xs={12} className="text-end mb-3">
        <Button onClick={() => setShowAddForm(true)}>Create Content</Button>
        <ContentCreateFormModal
          show={showAddForm}
          onHide={() => setShowAddForm(false)}
          onClose={onFormClose}
        />
      </Col>
      <Col md={12}>
      <Card>
          <Card.Body>
      <ContentTableList
        tableData={fetchAllContent}
        handleEditTableRow={handleEditTableRow}
        handleDeleteTableRow={handleDeleteTableRow}
        isLoading={fetchAllContent.isLoading || ContentDeleteMutation.isLoading}
      />
      </Card.Body>
      </Card>

      </Col>
     
      <ContentEditFormModal
        contentData={selectedRowData}
        show={showEditForm}
        onHide={handleCloseEditForm}
        onClose={handleCloseEditForm}
      />

      {/* Confirmation Dialog for Deleting */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this content?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(selectedRowData);
              handleCloseDeleteDialog();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ContentList;
