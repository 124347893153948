import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../../../services/users/users-service.js";
import UsersTableList from "./widgets/UsersTableList";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import UsersCreateFormModal from "./UsersCreateFormModal.jsx";
import UsersEditFormModal from "./UsersEditFormModal.jsx";

//
import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function UsersList() {
  const queryClient = useQueryClient();

  //get
  const fetchAllUsers = useQuery(["users"], getAllUsers, {
    onSuccess: (data) => {
      console.log("users : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  //delete
  const UsersDeleteMutation = useMutation(
    (variables) => deleteUsersById(variables),
    {
      onSuccess: (data) => {
        toast.success("User Deleted Successfully");
        queryClient.resetQueries(["users"]);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  const [showAddForm, setShowAddForm] = useState(false);
  const onFormClose = () => {
    setShowAddForm(false);
  };

  //edit form

  const [selectedRowData, setSelectedRowData] = useState();
  const [showEditForm, setShowEditForm] = useState(false);

  const handleCloseEditForm = () => {
    setSelectedRowData({ id: null });
    setShowEditForm(false);
  };

  const handleEditTableRow = (rowData) => {
    setSelectedRowData(rowData);
    setShowEditForm(true);
    console.log("SelectedRowData is : ", rowData);
  };

  //delete

  // const handleDeleteTableRow = (rowData) => {
  //   UsersDeleteMutation.mutate(rowData?.id);
  // };

  // State for delete confirmation dialog
  const [selectedRowDataToDelete, setSelectedRowDataToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Handler to open delete confirmation dialog
  const handleDeleteTableRow = (rowData) => {
    console.log("Handle click table row");
    setSelectedRowData(rowData);
    setOpenDeleteDialog(true);
  };

  // Handler to close delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setSelectedRowData(null);
    setOpenDeleteDialog(false);
  };

  // Handler to confirm delete
  const handleConfirmDelete = (rowData) => {
    if (rowData) {
      UsersDeleteMutation.mutate(rowData.id);
    }
  };

  //
  return (
    <div>
      <Col xs={12} className="text-end mb-3">
        <Button onClick={() => setShowAddForm(true)}>Create User</Button>
        <UsersCreateFormModal
          show={showAddForm}
          onHide={() => setShowAddForm(false)}
          onClose={onFormClose}
        />
      </Col>
      <UsersTableList
        tableData={fetchAllUsers}
        handleEditTableRow={handleEditTableRow}
        handleDeleteTableRow={handleDeleteTableRow}
        isLoading={fetchAllUsers.isLoading || UsersDeleteMutation.isLoading}
      />
      <UsersEditFormModal
        userData={selectedRowData}
        show={showEditForm}
        onHide={handleCloseEditForm}
        onClose={handleCloseEditForm}
      />

      {/* Confirmation Dialog for Deleting */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(selectedRowData);
              handleCloseDeleteDialog();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UsersList;
