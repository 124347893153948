import React from 'react'
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap'
import OutBlastTableList from './widgets/OutBlastTableList'
import { Link } from 'react-router-dom'

function OutBlastsPage() {
  return (
    <div>
         <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Blasts</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        <Card>
          <Card.Body>
            <h5>Blast Messages</h5>
            <OutBlastTableList />
          </Card.Body>
        </Card>
      </Col>
    </div>
  )
}

export default OutBlastsPage