import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import SummaryBoxCard from "./SummaryBoxCard";
import { getAllHitsSummary } from "../../../services/finance/payments-service.js";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { formatNumber } from "../../../utils/helper_functions";


function SummaryBarWidget() {
  const queryClient = useQueryClient();
  const [percentageChange, setPercentageChange] = useState({});
  const [counter, setCounter] = useState({});

  //
  const calculatePercentageChange = (total_hits, previous_total_hits) => {
    // Convert the input values to integer numbers
    total_hits = parseInt(total_hits, 10); // Using base 10 for decimal numbers
    previous_total_hits = parseInt(previous_total_hits, 10);

    // Check if the conversion resulted in valid numbers
    if (isNaN(total_hits) || isNaN(previous_total_hits)) {
      return "Invalid input. Please provide valid integer numbers.";
    }

    // Check if both total_hits and previous_total_hits are zero
    if (total_hits === 0 && previous_total_hits === 0) {
      return 0; // Return 0 as an integer
    }

    if (previous_total_hits ==0){
       previous_total_hits = 1
    }
    // Calculate the percentage change
    let percentageChange =
      ((total_hits - previous_total_hits) / previous_total_hits) * 100;

    return percentageChange;
  };

  //counter
  const calculateCounterValue = (total_hits, previous_total_hits) => {
    // Convert the input values to integer numbers
    total_hits = parseInt(total_hits, 10); // Using base 10 for decimal numbers
    previous_total_hits = parseInt(previous_total_hits, 10);

    // Check if the conversion resulted in valid numbers
    if (isNaN(total_hits) || isNaN(previous_total_hits)) {
      return "Invalid input. Please provide valid integer numbers.";
    }

    // Check if both total_hits and previous_total_hits are zero
    if (total_hits === 0 && previous_total_hits === 0) {
      return 0; // Return 0 as an integer
    }

    if (previous_total_hits ==0){
       previous_total_hits = 1
    }
    // Calculate the percentage change
    let counterValue = ((total_hits - previous_total_hits) / previous_total_hits) * 100;
    console.log(total_hits)
    console.log(previous_total_hits)
    console.log(counterValue)
    return counterValue;
  };

  //get
  const fetchAllHitsSummary = useQuery(
    ["finance", "hits-summary"],
    getAllHitsSummary,
    {
      onSuccess: (data) => {
        console.log("hits summary : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      },
      refetchInterval: 120 * 1000,
      refetchIntervalInBackground: false
    }
    
  );

  useEffect(() => {
    if (fetchAllHitsSummary?.data?.data) {
      setPercentageChange((oldData) => {
        return {
          totalHitsChange: calculatePercentageChange(
            fetchAllHitsSummary?.data?.data?.total_hits,
            fetchAllHitsSummary?.data?.data?.previous_total_hits
          ),
          successHitsChange: calculatePercentageChange(
            fetchAllHitsSummary?.data?.data?.success_hits,
            fetchAllHitsSummary?.data?.data?.previous_success_hits
          ),
          revenueChange: calculatePercentageChange(
            fetchAllHitsSummary?.data?.data?.revenue,
            fetchAllHitsSummary?.data?.data?.previous_revenue
          ),
          failedHitsChange: calculatePercentageChange(
            fetchAllHitsSummary?.data?.data?.failed_hits,
            fetchAllHitsSummary?.data?.data?.previous_failed_hits
          )
        };
      });

      setCounter((oldData) => {
        return {
          totalHitsCounterValue: calculateCounterValue(
            fetchAllHitsSummary?.data?.data?.total_hits,
            fetchAllHitsSummary?.data?.data?.previous_total_hits
          ),
          successHitsCounterValue: calculateCounterValue(
            fetchAllHitsSummary?.data?.data?.success_hits,
            fetchAllHitsSummary?.data?.data?.previous_success_hits
          ),
          revenueCounterValue: calculateCounterValue(
            fetchAllHitsSummary?.data?.data?.revenue,
            fetchAllHitsSummary?.data?.data?.previous_revenue
          ),
          failedHitsCounterValue: calculateCounterValue(
            fetchAllHitsSummary?.data?.data?.failed_hits,
            fetchAllHitsSummary?.data?.data?.previous_failed_hits
          )
        };
      });
    }
  }, [fetchAllHitsSummary?.data?.data]);
  console.log("fetchAllHitsSummary", fetchAllHitsSummary?.data?.data);

  return (
    <div>
      <Row className="gy-2">
        <Col lg={3} md={4} xs={6}>
          <SummaryBoxCard
            variant="info"
            label="Total Hits"
            value={formatNumber(fetchAllHitsSummary?.data?.data?.total_hits)}
            PercentageChange={percentageChange?.totalHitsChange?.toFixed(2)}
            counter={counter?.totalHitsCounterValue}
          />
        </Col>
        <Col lg={3} md={4} xs={6}>
          <SummaryBoxCard
            variant="success"
            label="Success"
            value={formatNumber(fetchAllHitsSummary?.data?.data?.success_hits)}
            counter={counter?.successHitsCounterValue}
            PercentageChange={percentageChange?.successHitsChange?.toFixed(2)}
          />
        </Col>
        <Col lg={3} md={4} xs={6}>
          <SummaryBoxCard
            variant="danger"
            label="Failed"
            value={formatNumber(fetchAllHitsSummary?.data?.data?.failed_hits)}
            PercentageChange={percentageChange?.failedHitsChange?.toFixed(2)}
            counter={counter?.failedHitsCounterValue}
          />
        </Col>
        <Col lg={3} md={12} xs={6}>
          <SummaryBoxCard
            variant="primary"
            label="Revenue"
            value={formatNumber(fetchAllHitsSummary?.data?.data?.revenue)}
            PercentageChange={percentageChange?.revenueChange?.toFixed(2)}
            counter={counter?.revenueCounterValue}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SummaryBarWidget;
