import axiosAPI from "../axiosApi";

export async function getAllAccountsProfile() {
  const response = await axiosAPI.get("accounts/profile/");
  return response;
}

export async function getAccountsProfileById(id) {
  const response = await axiosAPI.get(`accounts/profile/` + id);
  return response;
}

export async function postAccountsProfile(data) {
  const response = await axiosAPI.post(`accounts/profile/`, data);
  return response;
}

export async function updateAccountsProfile(id, data) {
  const response = await axiosAPI.put(`accounts/profile/${id}/`, data);
  return response;
}

export async function deleteAccountsProfileById(id) {
  const response = await axiosAPI.delete(`accounts/profile/${id}`);
  return response;
}
// "activity-logs";
