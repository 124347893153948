import React from "react";
import KeywordsDetailModule from "./widgets/KeywordsDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getAllKeyWords,
  getKeyWordsById,
  postKeyWords,
  updateKeyWords,
  deleteKeyWordsById
} from "../../services/content/keywords-service.js";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function KeywordsDetailsPage() {
  //
  const { state } = useLocation();
  let keywordId = state?.keywordId;
  //
  //get
  const fetchKeywordByIdQuery = useQuery(
    ["keywords", keywordId],
    () => getKeyWordsById(keywordId),
    {
      enabled: keywordId ? true : false,
      onSuccess: (data) => {
        console.log("keywordId detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/keywords" }}>
              keywords
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchKeywordByIdQuery.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Col>
      {!fetchKeywordByIdQuery.isLoading && (
        <>
          <KeywordsDetailModule
            keywordsData={fetchKeywordByIdQuery?.data?.data}
          />

        
        </>
      )}
    </>
  );
}

export default KeywordsDetailsPage;
