import React from "react";
import { Badge, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatNumber, formatShortDate } from "../../../utils/helper_functions";

function WalletsMinistatementTableModule({
  tableData = [],
  isLoading
}) {
  //
  return (
    <div>
      {isLoading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>
              <small>#</small>
            </td>
            <td>
              <small>Narration</small>
            </td>
           
            <td>
              <small>Value Number</small>
            </td>
            <td>
              <small>Created</small>
            </td>
            <td>
              <small>Lot</small>
            </td>
            
          </tr>
        </thead>
        <tbody>
          {!tableData || tableData?.data?.data?.length < 1 ? (
            <tr>
              <td colSpan="12">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display{" "}
                  </strong>
                </center>
              </td>
            </tr>
          ) : (
            tableData?.data?.data?.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.txn?.description}</td>
                <td>{formatNumber(data?.value_num)}</td>
                <td>{formatShortDate(data?.created)}</td>
                <td>{data?.lot}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default WalletsMinistatementTableModule;
