import React from "react";
import { Badge, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Check2Circle,
  CloudCheck,
  CloudCheckFill,
  XCircleFill,
  PencilSquare,
  Trash
} from "react-bootstrap-icons";
import moment from "moment";
import { formatNumber, formatShortDate } from "../../../utils/helper_functions";

function WalletsTableList({
  tableData = [],
  handleEditTableRow,
  handleDeleteTableRow,
  isLoading
}) {
  //
  return (
    <div>
      {isLoading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>
              <small>Id</small>
            </td>

            <td>
              <small>name</small>
            </td>
            <td>
              <small>Account Type</small>
            </td>
            <td>
              <small>Balance</small>
            </td>
            <td>
              <small>Owner</small>
            </td>
           
            <td>
              <small>Hidden</small>
            </td>
           
            <td>
              <small>System</small>
            </td>

            <td>
              <small>Updated At</small>
            </td>
            
            {(typeof handleEditTableRow === "function" ||
              typeof handleDeleteTableRow === "function") && (
              <td>
                <small>Actions</small>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!tableData || tableData?.data?.data?.results.length < 1 ? (
            <tr>
              <td colSpan="12">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display{" "}
                  </strong>
                </center>
              </td>
            </tr>
          ) : (
            tableData?.data?.data?.results.map((data, index) => (
              <tr key={index}>
                <th>{data?.id}</th>
                <td>
                  <Link
                    to={"/wallet/detail"}
                    state={{ walletPocketId: data?.id }}
                    className="text-decoration-none"
                  >
                    {data?.name}
                  </Link>
                </td>

                <td>{data?.account_type}</td>
                <td>{formatNumber(data?.balance)}</td>
                <td>{data?.owner}</td>
                <td>
                  {data?.is_hidden ? (
                    <Check2Circle color="green" />
                  ) : (
                    <XCircleFill color="red" />
                  )}
                </td>
                
                <td>
                  {data?.is_system ? (
                    <Check2Circle color="green" />
                  ) : (
                    <XCircleFill color="red" />
                  )}
                </td>
                <td>{formatShortDate(data?.created)}</td>
                <td>
                 
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default WalletsTableList;
