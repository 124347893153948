import React from "react";
import UssdSessionsDetailModule from "./widgets/UssdSessionsDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
  Container
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  fetchUssdSessions,
  fetchUssdSessionById
} from "../../../services/channels/ussd-sessions-service";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function UssdSessionsDetailsPage() {
  //
  const { state } = useLocation();
  let ussdSessionId = state?.ussdSessionId;
  //
  //get
  const fetchUssdSessionByIdQuery = useQuery(
    ["ussd-sessions", ussdSessionId],
    () => fetchUssdSessionById(ussdSessionId),
    {
      enabled: ussdSessionId ? true : false,
      onSuccess: (data) => {
        console.log("fetchUssdSessionByIdQuery detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/channels" }}>
              Channels
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: "/channels/sessions" }}
            >
              Sessions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchUssdSessionByIdQuery.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}

        {!fetchUssdSessionByIdQuery.isLoading && (
          <Col>
            <UssdSessionsDetailModule
              ussdSessionsData={fetchUssdSessionByIdQuery?.data?.data}
            />
            
          </Col>
        )}
      </Col>
    </>
  );
}

export default UssdSessionsDetailsPage;
