import React, { useState } from "react";
import { Button, Form, Col, Row, FloatingLabel } from "react-bootstrap";
import {
  getAllKeyWords,
  getKeyWordsById,
  postKeyWords,
  updateKeyWords,
  deleteKeyWordsById
} from "../../../services/content/keywords-service.js";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

function ContentsEditForm({ handleSubmit, contentData }) {
  const [formData, setFormData] = useState(contentData || {});
  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle switches
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleSubmit(event, formData);
    }
    setValidated(true);
  };

  //get keywords
  const fetchAllKeywords = useQuery(["keywords"], getAllKeyWords, {
    onSuccess: (data) => {
      console.log("keywords : ", data);
    },
    onError: (error, variables, context) => {
      console.log("error is : ", error);
    }
  });

  return (
    <Form onSubmit={handleFormSubmit} noValidate validated={validated}>
      <Row className="gx-3 gy-3">
        <Col md="12">
          <FloatingLabel controlId="floatingInputKeyword" label="Keyword">
            <Form.Select
              value={formData.keyword}
              name="keyword"
              onChange={handleChange}
              required
            >
              <option value="">Select a Key Word</option>
              {fetchAllKeywords?.data?.data?.results.map((data, index) => (
                <option key={index} value={data?.id}>
                  {data?.keyword}
                </option>
              ))}
              {/* Add more options as needed */}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a valid key word.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
          <FloatingLabel controlId="floatingInputCategory" label="Category">
            <Form.Control
              as="select"
              value={formData?.category || ""}
              name="category"
              onChange={handleChange}
              required
            >
              <option value="">Choose a category</option>
              <option value="text">Text</option>
              {/* Add other category options here */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a category.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="6">
          <FloatingLabel controlId="floatingInputStartDate" label="Start Date">
            <Form.Control
              type="date"
              value={formData?.start_date || ""}
              name="start_date"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid start date.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="6">
          <FloatingLabel controlId="floatingInputEndDate" label="End Date">
            <Form.Control
              type="date"
              value={formData?.end_date || ""}
              name="end_date"
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid end date.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>

        <Col md="12">
        <Form.Group className="mb-2" controlId="floatingInputMessage">
        <Form.Label>Message</Form.Label>
            <Form.Control
              type="text"
              value={formData?.message || ""}
              name="message"
              onChange={handleChange}
              as="textarea" 
              rows={4}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid message.
            </Form.Control.Feedback>
            </Form.Group>
        </Col>

        

        

        

        <Col md="12">
          <Form.Check
            type="switch"
            id="custom-switch-active"
            label="Active"
            name="is_active"
            onChange={handleChange}
            checked={formData?.is_active}
          />
        </Col>

        <Col md="12" className="d-grid gap-2">
          <Button variant="primary" type="submit" size="md">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ContentsEditForm;
