import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Spinner, Button, Form, FloatingLabel, Modal } from "react-bootstrap";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import {
  Check2Circle,
  CloudCheck,
  CloudCheckFill,
  XCircleFill,
  PencilSquare,
  Trash
} from "react-bootstrap-icons";
import moment from "moment";
import { toast } from "react-toastify";
import ProfileEditForm from "./ProfileEditForm";

function ProfileDetail({ userDetail }) {
  const [profileData, setProfileData] = useState(userDetail);

  useEffect(() => {
    setProfileData(userDetail);
  }, [userDetail]);

  //handle modal
  const [showEditForm, setShowEditForm] = useState(false);

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const handleEditTableRow = (rowData) => {
    setShowEditForm(true);
  };

  return (
    <>
      <Row className="justify-content-center align-items-center h-100">
        <Col lg="12">
          <Card className="mb-3" style={{ borderRadius: ".5rem" }}>
            <Row className="g-0">
              <Col
                md="4"
                className="gradient-custom text-center text-white"
                style={{
                  borderTopLeftRadius: ".5rem",
                  borderBottomLeftRadius: ".5rem",
                  background:
                    "linear-gradient(to right bottom, #0074D9, #00A5F8)"
                }}
              >
                <center>
                  <Avatar
                    src={profileData?.user_profile?.avatar}
                    alt="Avatar"
                    sx={{ my: 5, width: 80, height: 80 }}
                  />
                </center>

                <Typography variant="h5">
                  {profileData?.first_name} {profileData?.last_name}
                </Typography>
                <Typography variant="body1">
                  ROLE : {profileData?.is_staff ? "Staff" : "Not Staff"}
                </Typography>
                <Typography variant="h6">
                  <PencilSquare
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      marginRight: "1rem"
                    }}
                    onClick={() => {
                      setShowEditForm(true);
                    }}
                  />
                </Typography>
              </Col>
              <Col md="8">
                <Card.Body className="p-4">
                  <Typography variant="h6">Information</Typography>
                  <hr className="mt-0 mb-4" />
                  <Row className="pt-1">
                    <Col size="6" className="mb-3">
                      <Typography variant="h6">User Name</Typography>
                      <Typography variant="body2" className="text-muted">
                        {profileData?.username}
                      </Typography>
                    </Col>
                    <Col size="6" className="mb-3">
                      <Typography variant="h6">Phone Number</Typography>
                      <Typography variant="body2" className="text-muted">
                        {profileData?.user_profile?.phone_number}
                      </Typography>
                    </Col>
                  </Row>

                  <Typography variant="h6">Information</Typography>
                  <hr className="mt-0 mb-4" />
                  <Row className="pt-1">
                    <Col size="6" className="mb-3">
                      <Typography variant="h6">Email</Typography>
                      <Typography variant="body2" className="text-muted">
                        {profileData?.email}
                      </Typography>
                    </Col>
                    <Col size="6" className="mb-3">
                      <Typography variant="h6">Date Joined</Typography>
                      <Typography variant="body2" className="text-muted">
                        {moment(profileData?.date_joined).format(
                          "MMMM D, YYYY h:mm A"
                        )}
                      </Typography>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-start">
                    <a href="#!">
                      <Facebook className="me-3" fontSize="large" />
                    </a>
                    <a href="#!">
                      <Twitter className="me-3" fontSize="large" />
                    </a>
                    <a href="#!">
                      <Instagram className="me-3" fontSize="large" />
                    </a>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showEditForm}
        onHide={handleCloseEditForm}
        onClose={handleCloseEditForm}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Profile Edit Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Fill in the form below</p>

          <ProfileEditForm profileData={profileData} />
          {/* <p>{editProjectsMutation.status}</p> */}
          {/* {editUsersMutation.isLoading && (
            <center>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </center>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ProfileDetail;
