import React, { useState } from "react";
import { Breadcrumb, Button, Card, Col, Row } from "react-bootstrap";
import USSDMenuList from "./widgets/USSDMenuList";
import UssdMenueCreateFormModal from "./UssdMenueCreateFormModal.jsx";

function USSDMenuPage() {
  const [showMenuForm, setShowMenuForm] = useState(false);

  //show create form
  const [showAddForm, setShowAddForm] = useState(false);
  const onFormClose = () => {
    setShowAddForm(false);
  };
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Channels</Breadcrumb.Item>
            <Breadcrumb.Item active>Ussd Menu</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} className="text-end mb-3">
                  <Button onClick={() => setShowAddForm(true)}>
                    Create USSD Menu
                  </Button>
                  <UssdMenueCreateFormModal
                    show={showAddForm}
                    onHide={() => setShowAddForm(false)}
                    onClose={onFormClose}
                  />
                </Col>
                <Col md={12}>
                  <USSDMenuList />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default USSDMenuPage;
