import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import { useQuery } from "@tanstack/react-query";

function UsersRowForm({ handleSubmit, UsersData }) {
  const [formData, setFormData] = useState({
    is_staff: false
  });

  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle switches
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.log("formdata xx : ", formData);
      handleSubmit(event, formData);
      setFormData({
        is_staff: false
      });
    }
    setValidated(true);
  };

  return (
    <>
      <Form
        onSubmit={handleFormSubmit}
        noValidate
        validated={validated}
        // onSubmit={(event) => handleSubmit(event, formData, validateForm)}
      >
        <Row className="gx-3 gy-3">
          <Col md="12">
            <FloatingLabel
              controlId="floatingInput11"
              label="First Name"
              className=""
            >
              <Form.Control
                type="text"
                value={formData?.first_name}
                name="first_name"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid first name.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput2"
              label="Last Name"
              className=""
            >
              <Form.Control
                type="text"
                value={formData?.last_name}
                name="last_name"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid last name.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput3"
              label="Email"
              className=""
            >
              <Form.Control
                type="text"
                value={formData?.email}
                name="email"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput4"
              label="User Name"
              className=""
            >
              <Form.Control
                type="text"
                value={formData?.username}
                name="username"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid User name.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput5"
              label="Phone Number"
              className=""
            >
              <Form.Control
                type="text"
                value={formData?.phone_number}
                name="phone_number"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Phone Number.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            {/* <Form.Check
              type="switch"
              id="custom-switch-active"
              label="Active"
              name="is_active"
              onChange={handleChange}
              checked={formData?.is_active}
            /> */}
            <Form.Check
              type="switch"
              id="custom-switch-staff"
              label={formData?.is_staff ? "Staff" : "Not Staff"}
              name="is_staff"
              onChange={handleChange}
              checked={formData?.is_staff ? true : false}
            />
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput6"
              label="Password"
              className=""
            >
              <Form.Control
                type="password"
                value={formData?.password}
                name="password"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Password.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12">
            <FloatingLabel
              controlId="floatingInput7"
              label="Repeat Password"
              className=""
            >
              <Form.Control
                type="password"
                value={formData?.password2}
                name="password2"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Password.
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col md="12" className="d-grid gap-2">
            <Button variant="primary" type="submit" size="md">
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      {/* {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )} */}
    </>
  );
}

export default UsersRowForm;
