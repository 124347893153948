import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";
import { Alert } from "react-bootstrap";
import WalletWithdrawCreateFormModal from "../WalletWithdrawCreateFormModal";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { formatShortDate } from "../../../utils/helper_functions";

import {
  getAllFinancePocket,
  getAllFinancePocketById,
  getAllFinancePocketByIdMinStatement,
  getAllFinancePocketPrimary
} from "../../../services/finance/pockets-service.js";

import WalletsMinistatementTableModule from "./WalletsMinistatementTableModule";

// const poketsData = {
//   "id": 0,
//   "balance": "-07592546",
//   "name": "string",
//   "account_type": "ROOT",
//   "currency": "stri",
//   "description": "string",
//   "is_hidden": true,
//   "is_placeholder": true,
//   "is_primary": true,
//   "is_system": true,
//   "updated": "2023-08-28T08:18:56.315Z",
//   "created": "2023-08-28T08:18:56.315Z",
//   "parent": 0,
//   "owner": 0,
//   "author": 0
// }

function WalletsPocketDetailModule({ poketsDataQuery }) {
  console.log("poketsData : ", poketsDataQuery);
  const [poketsData, setPoketsData] = useState(poketsDataQuery?.data?.data);

  useEffect(() => {
    setPoketsData(poketsDataQuery?.data?.data);
  }, [poketsDataQuery]);

  //========== wallet transaction ==========

  const [showAddForm, setShowAddForm] = useState(false);
  const onFormClose = () => {
    setShowAddForm(false);
  };

  //mini staement
  const fetchMinistaementsByPockeIdQuery = useQuery(
    ["pocket", "ministatement", poketsData?.id],
    () => getAllFinancePocketByIdMinStatement(poketsData?.id),
    {
      enabled: poketsData?.id ? true : false,
      onSuccess: (data) => {
        console.log("ministatement under pocket detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );
  return (
    <>
      <Container className="mt-5">
        <Col xs={12} className="text-end mb-3">
          <Button onClick={() => setShowAddForm(true)}>WithDraw</Button>
          <WalletWithdrawCreateFormModal
            show={showAddForm}
            onHide={() => setShowAddForm(false)}
            onClose={onFormClose}
          />
        </Col>
        {/* <h2>Keywords Details</h2> */}

        {!Array.isArray(poketsData) ||
          (poketsData.length < 1 && (
            <Row>
              <Alert variant="danger">
                <center>No records found.</center>
              </Alert>
            </Row>
          ))}
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>Poket Details</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Table>
                      <tbody>
                        <tr>
                          <th className="text-nowrap">ID</th>
                          <td>{poketsData?.id}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Balance</th>
                          <td>{poketsData?.balance}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Name</th>
                          <td>{poketsData?.name}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Account Type</th>
                          <td>{poketsData?.account_type}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Currency</th>
                          <td>{poketsData?.currency}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={4}>
                    <Table>
                      <tbody>
                        <tr>
                          <th className="text-nowrap">Description</th>
                          <td>{poketsData?.description}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Hidden</th>
                          <td>{poketsData?.is_hidden ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Placeholder</th>
                          <td>{poketsData?.is_placeholder ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Primary</th>
                          <td>{poketsData?.is_primary ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">System</th>
                          <td>{poketsData?.is_system ? "Yes" : "No"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={4}>
                    <Table>
                      <tbody>
                        <tr>
                          <th className="text-nowrap">Created At</th>

                          <td>
                            {formatShortDate(poketsData?.created)}
                            {/* {moment(poketsData?.created).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )} */}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Updated At</th>
                          <td>
                            {/* {moment(poketsData?.updated).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )} */}
                            {formatShortDate(poketsData?.updated)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Parent</th>
                          <td>{poketsData?.parent}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Author</th>
                          <td>{poketsData?.author}</td>
                        </tr>
                        <tr>
                          <th className="text-nowrap">Owner</th>
                          <td>{poketsData?.owner}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>Poket Details</Card.Header>
              <Card.Body>
                <Row>
                  <WalletsMinistatementTableModule
                    tableData={fetchMinistaementsByPockeIdQuery}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WalletsPocketDetailModule;
