import React, { Suspense, useEffect, useState, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import useAuthContext from "./context/AuthContext";

// import LoginPage from "./components/auth/LoginPage";
import RegistrationPage from "./components/auth/RegistrationPage";
import PageNotFound from "./components/common/PageNotFound";
import NotAuthorised from "./components/common/NotAuthorised";
import PrivateAdmin from "./components/auth/PrivateAdmin";
import DashOutlet from "./components/common/DashOutlet";
import NewLoginPage from "./components/auth/NewLoginPage";

function App() {
  const {
    getUserLoggedInUserDataQuery,
    setUserId,
    authUserProfile,
    setAuthUserProfile
  } = useAuthContext();
  let myroutes = AppRoutes();
  const [defaultRoutes, setDefaultRoutes] = useState(myroutes);

  useEffect(() => {
    setDefaultRoutes(myroutes);
  }, [myroutes]);

  return (
    <div className="App">
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateAdmin>
              <DashOutlet />
            </PrivateAdmin>
          }
        >
          {defaultRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              element={<route.element authUserProfile={authUserProfile} />}
            />
          ))}
        </Route>
        <Route
          path="/login"
          element={
            <NewLoginPage
              getUserLoggedInUserDataQuery={getUserLoggedInUserDataQuery}
              setUserId={setUserId}
              setAuthUserProfile={setAuthUserProfile}
              authUserProfile={authUserProfile}
            />
          }
        />
        <Route path="/signup" element={<RegistrationPage />} />
        <Route path="403" element={<NotAuthorised />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
