import React from "react";
import { Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Check2Circle,
  XCircleFill,
  PencilSquare,
  Trash
} from "react-bootstrap-icons";
import { formatDate, formatShortDate, formatSmartDate, shortText } from "../../../utils/helper_functions";

function ContentTableList({
  tableData = [],
  handleEditTableRow,
  handleDeleteTableRow,
  isLoading
}) {
  //
  return (
    <div>
      {isLoading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>
              ID
            </td>

            <td>
              Message
            </td>
            
            <td className="d-none d-md-table-cell">
              Start Date
            </td>
            <td className="d-none d-md-table-cell">
              End Date
            </td>
            <td>
              Active
            </td>
            <td>
              Created
            </td>
            <td>
              Author
            </td>
            {(typeof handleEditTableRow === "function" ||
              typeof handleDeleteTableRow === "function") && (
              <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                Actions
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!tableData || tableData?.data?.data?.results.length < 1 ? (
            <tr>
              <td colSpan="10">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display{" "}
                  </strong>
                </center>
              </td>
            </tr>
          ) : (
            tableData?.data?.data?.results.map((data, index) => (
              <tr key={index}>
                <th>
                  {" "}
                  <Link
                    // to={"/accounts/profile" + item.id}
                    to="#"
                    state={{ contentId: data?.id }}
                    className="text-decoration-none"
                  >
                    {index + 1}
                  </Link>
                </th>
                <td>{shortText(data?.message, 50)}<br/>
                <small className='d-md-none'>{formatDate(data?.start_date)} - {formatDate(data?.end_date)}</small><br/>
                </td>
                <td className="d-none d-md-table-cell">
                  {formatDate(data?.start_date)}
                </td>
                <td className="d-none d-md-table-cell">{formatDate(data?.end_date)}</td>
                <td>
                  {data?.is_active ? (
                     <Check2Circle color="green" />
                  ) : (
                    <XCircleFill color="secondary" />
                  )}
                </td>
                <td>{formatSmartDate(data?.created)}</td>
                <td>{data?.author?.username}</td>
                {(typeof handleEditTableRow === "function" ||
                  typeof handleDeleteTableRow === "function") && (
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {typeof handleEditTableRow === "function" && (
                        <PencilSquare
                          style={{
                            cursor: isLoading ? "not-allowed" : "pointer",
                            color: isLoading ? "gray" : "blue",
                            opacity: isLoading ? 0.5 : 1,
                            marginRight: "1rem"
                          }}
                          onClick={
                            isLoading ? null : () => handleEditTableRow(data)
                          }
                        />
                      )}

                      {typeof handleDeleteTableRow === "function" && (
                        <Trash
                          style={{
                            cursor: isLoading ? "not-allowed" : "pointer",
                            color: isLoading ? "gray" : "red",
                            opacity: isLoading ? 0.5 : 1
                          }}
                          onClick={
                            isLoading ? null : () => handleDeleteTableRow(data)
                          }
                        />
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default ContentTableList;
