import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";

function UssdMenueDetailModule({ ussdmenuData }) {
  console.log("ussdmenuData : ", ussdmenuData);
  return (
    <Container className="mt-5">
      {/* <h2>Ussd Menue Details</h2> */}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Ussd Menu Information</Card.Header>
            <Card.Body>
              <Row className="g-3">
                <Col md={12}>
                  {ussdmenuData?.content}
                </Col>
                <Col md={4}>
                
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">ID</th>
                        <td>{ussdmenuData?.id}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Action</th>
                        <td>{ussdmenuData?.action}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Menu Type</th>
                        <td>{ussdmenuData?.menu_type}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Response</th>
                        <td>{ussdmenuData?.response}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      
                      <tr>
                        <th className="text-nowrap">Validator</th>
                        <td>{ussdmenuData?.validator}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Processor</th>
                        <td>{ussdmenuData?.processor}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Created At</th>
                        <td>
                          {moment(ussdmenuData?.created).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Updated At</th>
                        <td>
                          {moment(ussdmenuData?.updated).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Parent</th>
                        <td>{ussdmenuData?.parent}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Author</th>
                        <td>{ussdmenuData?.author}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UssdMenueDetailModule;
