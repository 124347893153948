import React from "react";
import { ArrowUp, ArrowDown } from "react-bootstrap-icons"; // You'll need ArrowDown for the downward arrow
import { Card, Col, ProgressBar, Row } from "react-bootstrap";

function SummaryBoxCard(props) {
  // Determine the arrow and color based on PercentageChange
  let arrow;
  let color;

  if (props.PercentageChange > 0) {
    arrow = <ArrowUp color="green" />;
    color = "green";
  } else if (props.PercentageChange < 0) {
    arrow = <ArrowDown color="red" />;
    color = "red";
  } else {
    arrow = "-";
    color = "black";
  }

  // Determine the variant based on the counter value
  let variant;

  if (props.counter < 30) {
    variant = "danger";
  } else if (props.counter >= 30 && props.counter < 50) {
    variant = "info";
  } else if (props.counter >= 50 && props.counter < 70) {
    variant = "primary";
  } else {
    variant = "success";
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <h6>{props.label}</h6>
          <Row className="mb-1">
            <Col md={8}>
              <h4>{props.value}</h4>
            </Col>
            <Col md={4} className="text-right">
              <span
                className="pull-end"
                style={{ whiteSpace: "nowrap", float: "right" }}
              >
                {arrow}
                <small style={{ color }}>{props.PercentageChange}%</small>
              </span>
            </Col>
          </Row>
          <ProgressBar striped variant={variant} now={props.counter} />
        </Card.Body>
      </Card>
    </div>
  );
}

export default SummaryBoxCard;
