import React, { useState, useEffect } from "react";
import {
  Spinner,
  Button,
  Form,
  Col,
  Row,
  FloatingLabel,
  Modal
} from "react-bootstrap";

import {
  fetchUssdMenus,
  createUssdMenu,
  fetchUssdMenuById,
  updateUssdMenuById,
  deleteUssdMenuById
} from "../../../services/channels/ussd-menu-service";

import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import USSDMenuEditForm from "./widgets/USSDMenuEditForm.js";

function UssdMenuEditFormModal({ ussdMenuData, ...props }) {
  const queryClient = useQueryClient();
  const [validationErrors, setValidationErrors] = useState([]);

  const editUssdMenuMutation = useMutation({
    mutationFn: (variables) => updateUssdMenuById(ussdMenuData?.id, variables),
    onSuccess: () => {
      toast.success("Ussd Menu Updated Successfully");
      queryClient.resetQueries(["ussd-menus"]);
      props.onHide()
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error updating Ussd Menu ! please contact the administrator"
        );
      }
    }
  });

  const handleSubmit = (event, data) => {
    console.log(data);
    event.preventDefault();
    setValidationErrors([]);

    editUssdMenuMutation.mutate(data);

    // toast.warning("check your capital current ratio");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ussd Menu Edit Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {validationErrors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.key} : {error.value}
                </li>
              ))}
            </ul>
          </div>
        )}
        <p>Fill in the form below</p>

        <USSDMenuEditForm
          ussdMenuData={ussdMenuData}
          handleSubmit={handleSubmit}
        />
        {/* <p>{editProjectsMutation.status}</p> */}
        {editUssdMenuMutation.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UssdMenuEditFormModal;
