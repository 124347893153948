import React, { useState } from "react";
import { Alert, Table } from "react-bootstrap";
import {
  fetchUssdSessions,
  fetchUssdSessionById
} from "../../../../services/channels/ussd-sessions-service";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { formatNumber, formatShortDate } from "../../../../utils/helper_functions";
import Pager from "../../../common/Pager";

function SessionList(props) {
  const [page, setPage] = useState(1)
  const setCurrentPage = (e, currentPage) =>{
    setPage(prevState => currentPage)
}

  const {
    isLoading,
    isError,
    error,
    data: sessions,
    isFetching,
    isPreviousData
  } = useQuery({
    queryKey: ["ussd-sessions", page, {...props.filter}],
    queryFn: () => fetchUssdSessions({page:page, ...props.filter})
  });
  return (
    <>
    {isLoading==false && <p className="pull-end text-right text-end">Showing {50*page} of {formatNumber(sessions.data.count)} results</p>}
    <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>ID</td>
            <td>Phone</td>
            <td>SessionID</td>
            <td>Type</td>
            <td>LastActivity</td>
            <td>Created</td>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            <Alert variant="danger">{error.message}</Alert>
          ) : (
            <>
              {sessions.data.results.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <Link
                      to="/channels/sessions/detail"
                      state={{ ussdSessionId: item?.id }}
                      className="text-decoration-none"
                    >
                      {item?.phone}
                    </Link>
                  </td>

                  <td>{item?.session_id}</td>
                  <td>{item?.menu_type}</td>
                  <td>{formatShortDate(item?.last_activity)}</td>
                  <td>{formatShortDate(item?.created)}</td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
      {isLoading==false && <Pager numberOfPages={Math.floor(sessions?.data.count/50)} activePage={page} goToPage={setCurrentPage} />}
    </>
  );
}

export default SessionList;
