import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/auth/auth-service";
import UserLayout from "../common/UserLayout";

const PrivateAdmin = ({ children }) => {
  const navigate = useNavigate();
  return isAuthenticated() ? (
    <>
      <UserLayout>{children}</UserLayout>
    </>
  ) : isAuthenticated() ? (
    <Navigate to="/403" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateAdmin;
