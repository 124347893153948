import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Line } from 'react-chartjs-2';
  import {faker} from '@faker-js/faker'


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Payment Hits by Period',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  // export const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'MTN',
  //       data: labels.map(() => faker.datatype.number({ min: 500, max: 1000 })),
  //       borderColor: 'rgb(255, 99, 132)',
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //   ],
  // };

function LineGraphSummary(props) {
  const data = {
    labels:props.labels,
    datasets: [
      {
        label: 'All',
        data: props.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
      },
    ],
  };
  return (
    <div>
        <Row>
            <Col>
                <Bar options={options} data={data} />
            </Col>
        </Row>
    </div>
  )
}

export default LineGraphSummary