import React from "react";
import MessagesDetailModule from "./widgets/MessagesDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
  Container
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getAllMessages,
  getMessagesById,
  postMessages,
  updateMessages,
  deleteMessagesById
} from "../../services/content/messages-service.js";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function MessagesDetailsPage() {
  //
  const { state } = useLocation();
  let messageId = state?.messageId;
  //
  //get
  const fetchMessageById = useQuery(
    ["payments", messageId],
    () => getMessagesById(messageId),
    {
      enabled: messageId ? true : false,
      onSuccess: (data) => {
        console.log("payments detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/messages" }}>
              Messages
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchMessageById.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
        {!fetchMessageById.isLoading && (
          <>
            <MessagesDetailModule messageData={fetchMessageById?.data?.data} />
           
          </>
        )}
      </Col>
    </>
  );
}

export default MessagesDetailsPage;
