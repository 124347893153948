import React from "react";
import UssdMenueDetailModule from "./widgets/UssdMenueDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
  Container
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  fetchUssdMenus,
  createUssdMenu,
  fetchUssdMenuById,
  updateUssdMenuById
} from "../../../services/channels/ussd-menu-service";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function UssdMenueDetailsPage() {
  //
  const { state } = useLocation();
  let ussdmenuId = state?.ussdmenuId;
  //
  //get
  const fetchUssdMenuByIdQuery = useQuery(
    ["ussd-menus", ussdmenuId],
    () => fetchUssdMenuById(ussdmenuId),
    {
      enabled: ussdmenuId ? true : false,
      onSuccess: (data) => {
        console.log("ussdmenuId detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("ussdmenuId error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: "/channels/menus" }}
            >
              ussdMenu
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchUssdMenuByIdQuery.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}

        {!fetchUssdMenuByIdQuery.isLoading && (
          <>
            <UssdMenueDetailModule
              ussdmenuData={fetchUssdMenuByIdQuery?.data?.data}
            />
            
          </>
        )}
      </Col>
    </>
  );
}

export default UssdMenueDetailsPage;
