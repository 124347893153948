import axiosAPI from "../axiosApi";

export async function fetchAllOutBlasts(params) {
  const response = await axiosAPI.get("marketing/outblasts/", { params: params, timeout:9000 });
  return response;
}

export async function fetchOutBlastById(id) {
  const response = await axiosAPI.get(`marketing/outblasts/` + id);
  return response;
}

export async function postOutBlast(data) {
  const response = await axiosAPI.post(`marketing/contacts/`, data);
  return response;
}

export async function updateOutBlast(id, data) {
  const response = await axiosAPI.put(`marketing/outblasts/${id}/`, data);
  return response;
}


export async function deleteOutBlastById(id) {
  const response = await axiosAPI.delete(`marketing/outblasts/${id}`);
  return response;
}