import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap'
import ContactTableList from './widgets/ContactTableList'
import { Link } from 'react-router-dom'
import ContactForm from './widgets/ContactForm'

function ContactsPage() {
    const [showContactForm, setShowContactForm] = useState(false)
  return (
    <div>
         <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Messages</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
        <Row className='g-3'>
            <Col md={12}>
                <Button variant='primary' onClick={() => setShowContactForm(true)}>Upload Contacts</Button>
                <ContactForm show={showContactForm} onHide={() => setShowContactForm(false)} />
            </Col>
            <Col md={12}>
                <Card>
                    <Card.Body>
                        <ContactTableList />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default ContactsPage