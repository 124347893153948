import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";
import { Alert } from "react-bootstrap";
import WalletWithdrawCreateFormModal from "../WalletWithdrawCreateFormModal";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import WalletsMinistatementTableModule from "./WalletsMinistatementTableModule";

import {
  getAllFinancePocket,
  getAllFinancePocketById,
  getAllFinancePocketByIdMinStatement,
  getAllFinancePocketPrimary
} from "../../../services/finance/pockets-service.js";
import { formatNumber } from "../../../utils/helper_functions";

// const poketsData = {
//   "id": 0,
//   "balance": "-07592546",
//   "name": "string",
//   "account_type": "ROOT",
//   "currency": "stri",
//   "description": "string",
//   "is_hidden": true,
//   "is_placeholder": true,
//   "is_primary": true,
//   "is_system": true,
//   "updated": "2023-08-28T08:18:56.315Z",
//   "created": "2023-08-28T08:18:56.315Z",
//   "parent": 0,
//   "owner": 0,
//   "author": 0
// }

function DashboardWalletsPocketDetailModule() {
  //Get Non Admin Pocket
  //get
  const fetchFinancePocketPrimaryQuery = useQuery(
    ["pockets", "primary"],
    getAllFinancePocketPrimary,
    {
      onSuccess: (data) => {
        console.log("pockets primary : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  const [poketsData, setPoketsData] = useState(
    fetchFinancePocketPrimaryQuery?.data?.data
  );

  useEffect(() => {
    setPoketsData(fetchFinancePocketPrimaryQuery?.data?.data);
  }, [fetchFinancePocketPrimaryQuery]);

  //========== wallet transaction ==========

  const [showAddForm, setShowAddForm] = useState(false);
  const onFormClose = () => {
    setShowAddForm(false);
  };

  //mini staement
  const fetchMinistaementsByPockeIdQuery = useQuery(
    ["pocket", "ministatement", poketsData?.id],
    () => getAllFinancePocketByIdMinStatement(poketsData?.id),
    {
      enabled: poketsData?.id ? true : false,
      onSuccess: (data) => {
        console.log("ministatement under pocket detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );
  return (
    <>
        {/* <h2>Keywords Details</h2> */}

        {!Array.isArray(poketsData) ||
          (poketsData.length < 1 && (
            <Row>
              <Alert variant="danger">
                <center>No records found.</center>
              </Alert>
            </Row>
          ))}
        <Row className="mt-3 mb-3">
          <Col md={12} className="text-center">
          <h3>{formatNumber(poketsData?.balance)}/=</h3>
          <h6>Current Balance</h6>
            
          </Col>
        </Row>
  
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>Poket Details</Card.Header>
              <Card.Body>
                <Row>
                  <WalletsMinistatementTableModule
                    tableData={fetchMinistaementsByPockeIdQuery}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </>
  );
}

export default DashboardWalletsPocketDetailModule;
