import axiosAPI from "../axiosApi";

export async function fetchUssdSessions(params={}) {
  const response = await axiosAPI.get("channels/ussd/sessions/", {params:params});
  return response;
}

export async function fetchUssdSessionById(id) {
    const response = await axiosAPI.get(`channels/ussd/sessions/${id}/`);
    return response;
  }