import React, { useState } from "react";
import { Table, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Check2Circle,
  XCircleFill,
} from "react-bootstrap-icons";
import { formatNumber, formatShortDate, formatSmartDate } from "../../../../utils/helper_functions";
import { getAllFinancePayments } from "../../../../services/finance/payments-service";
import { useQuery } from "@tanstack/react-query";
import Pager from "../../../common/Pager";
import StatusBadge from "../../../common/StatusWidget";

function PaymentsTableList(props) {
  const [page, setPage] = useState(1)
  
    const { isLoading, isError, error, data:payments, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["payments", page,{...props.filter}],
      queryFn: () => getAllFinancePayments({page:page, ...props.filter}),
      keepPreviousData: true
    });
    const setCurrentPage = (e, currentPage) =>{
        
        setPage(prevState => currentPage)
    }

  return (
    <div>
      {isLoading ? (
        <center><Spinner /></center>
          ) : isError ? (
            <Alert variant="danger">Error: {error.message}</Alert>
          ) : (<>
            <p className="pull-end text-right text-end">Showing {50*page} of {formatNumber(payments.data.count)} results</p>
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>Account </td>
            <td> Amount </td>
            <td className='d-none d-md-table-cell'> Narration  </td>
            <td className='d-none d-sm-table-cell'>Posted  </td>
            <td>Status</td>
            <td className='d-none d-sm-table-cell'> Created </td>
            
          </tr>
        </thead>
        <tbody>
          {payments.data.results.length == 0 ? (
            <tr>
              <td colSpan="9">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display
                  </strong>
                </center>
              </td>
            </tr>
          ) : (<>
            {isFetching ? <center><Spinner /></center> : <></>}
            {isFetching==false && payments.data.results.map((data, index) => (
              <tr key={index}>
                <td>
                  
                  <Link
                    // to={"/accounts/profile" + item.id}
                    to="/payments/detail"
                    state={{ paymentId: data?.id }}
                    className="text-decoration-none"
                  >
                    {data?.account}<br/>
                    
                  </Link>
                  <small className='d-md-none'>{formatShortDate(data?.created)}</small>
                </td>
                <td>{data?.amount}</td>
                
                <td className='d-none d-md-table-cell'>{data?.narration}</td>
                
                <td className='d-none d-sm-table-cell'>
                  {data?.is_posted ? (
                    <Check2Circle color="green" />
                  ) : (
                    <XCircleFill color="red" />
                  )}
                </td>
            
                <td>
                  <StatusBadge value={data?.status}/>
                 
                </td>
                
                <td className='d-none d-sm-table-cell'>{formatSmartDate(data?.created)}</td>
              </tr>
            ))}
            </>)}
        </tbody>
      </Table>
      <Pager numberOfPages={Math.floor(payments.data.count/50)} activePage={page} goToPage={setCurrentPage} />
      </>)}
    </div>
  );
}

export default PaymentsTableList;
