import React, { useState } from "react";
import profilePic from "../../assets/default.png";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Image,
  ProgressBar,
  Row,
  Table,
  ToggleButton
} from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../services/auth/auth-service";
import LineGraphSummary from "./widgets/LineGraphSummary";
import SummaryBarWidget from "./widgets/SummaryBarWidget";
import HotKeywords from "./widgets/HotKeywords";
import GraphWidget from "./widgets/GraphWidget";
import WeeklyGraphWidget from "./widgets/WeeklyGraphWidget";

function DashboardPage({ authUserProfile }) {
  const navigate = useNavigate();
  const [graph, setGraph] = useState("Weekly")
  
  return (
    <div>
      <Row className="mt-2 gy-2">
        <Col md={1} xs={3}>
          <Image
            // src={authUserProfile?.user_profile?.avatar}
            src={profilePic}
            width={150}
            height={150}
            roundedCircle
            fluid
          />
        </Col>
        <Col md={8} xs={9}>
          <h4>
            Welcome, {authUserProfile?.first_name} {authUserProfile?.last_name}
          </h4>
          <span>Here's what's happening with your account today.</span>
        </Col>
        {!authUserProfile?.is_staff && (
          <Col>
            <div className="d-grid gap-2">
              <Button
                variant="outline-danger"
                className="float-end"
                // onClick={() => !authUserProfile?.is_staff && navigate("/wallet")}
                onClick={() => navigate("/pocket/detail")}
              >
                Mini Statement
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <Row className="mt-3 gy-3">
        <Col md={12}>
          <SummaryBarWidget />
        </Col>
      </Row>
      <Row className="mt-2 gy-3">
        <Col lg={8} md={12}>
        <Card>
          <Card.Header>
            <Col md={8}>
              Hits Graph
            </Col>
            <Col md={4}>
               
            </Col>
            </Card.Header>
              <Card.Body> 
                <Col md={12}>
                
                <ButtonGroup>
                <ToggleButton
                    id={`radio-1`}
                    type="radio"
                    variant={'outline-success'}
                    name="radio"
                    value='Monthly'
                    checked={graph === 'Monthly'}
                    onChange={(e) => setGraph(e.target.value)}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    id={`radio-Weekly`}
                    type="radio"
                    variant={'outline-success'}
                    name="radio"
                    value='Weekly'
                    checked={graph === 'Weekly'}
                    onChange={(e) => setGraph(e.target.value)}
                  >
                    Weekly
                  </ToggleButton>
                </ButtonGroup>
                </Col>
                
                {graph =='Weekly' ? (<WeeklyGraphWidget />):(<GraphWidget />)}
                
          </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={12} xs={12}>
          <Card>
            <p className="p-2 border-bottom">Top Selling Product</p>
            <Card.Body>
               <HotKeywords />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardPage;
