import React, { useState } from "react";
import ProfilesPage from "./profiles/ProfilesPage";
import UsersPage from "./users/UsersPage";
import { Breadcrumb, Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function AccountsPage() {
  //
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Accounts</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <UsersPage />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AccountsPage;
