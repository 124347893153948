import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

function WalletWithdrawForm({ handleSubmit }) {
  const [formData, setFormData] = useState({
    account: 0,
    amount: "",
    narration: "",
    post_date: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit(formData);
    setFormData({
      account: 0,
      amount: "",
      narration: "",
      post_date: ""
    });
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Account</Form.Label>
        <Form.Control
          type="number"
          name="account"
          value={formData.account}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          type="text"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Narration</Form.Label>
        <Form.Control
          type="text"
          name="narration"
          value={formData.narration}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Post Date</Form.Label>
        <Form.Control
          type="date"
          name="post_date"
          value={formData.post_date}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Save
      </Button>
    </Form>
  );
}

export default WalletWithdrawForm;
