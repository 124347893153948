import axiosAPI from "../axiosApi";

export async function fetchUssdMenus() {
  const response = await axiosAPI.get("channels/ussd/menus/");
  return response;
}

export async function createUssdMenu(payload) {
  const response = await axiosAPI.post("channels/ussd/menus/", payload);
  return response;
}

export async function fetchUssdMenuById(id) {
  const response = await axiosAPI.get(`channels/ussd/menus/${id}/`);
  return response;
}

export async function updateUssdMenuById(id, payload) {
  const response = await axiosAPI.put(`channels/ussd/menus/${id}/`, payload);
  return response;
}

export async function deleteUssdMenuById(id) {
  const response = await axiosAPI.delete(`channels/ussd/menus/${id}`);
  return response;
}
