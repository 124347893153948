import React, { useState } from "react";
import { Breadcrumb, Card, Col, Form, Row } from "react-bootstrap";
import SessionList from "./widgets/SessionList";

function USSDSessionPage() {
  const [filters, setFilters] = useState()
  const [formData, setFormData] = useState({
    
  });
  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilters(formData)
  }
  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <h3>Sessions</h3>
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Channels</Breadcrumb.Item>
            <Breadcrumb.Item active>Sessions</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
            <Form onSubmit={handleSubmit} className="mb-1" >
            <Row className="mb-1">
              <Col md={4}>
                <Form.Control name="phone" value={formData.account} placeholder="Search phone number" onChange={handleChange} />
              </Col>
            </Row>
            </Form>
              <SessionList filter={filters} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default USSDSessionPage;
