import React from "react";
import PaymentsDetailModule from "./widgets/PaymentsDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
  Container
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  getAllFinancePayments,
  getFinancePaymentsById,
  postFinancePayments,
  updateFinancePayments,
  deleteFinancePaymentsById
} from "../../../services/finance/payments-service.js";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function PaymentDetailsPage() {
  //
  const { state } = useLocation();
  let paymentId = state?.paymentId;
  //
  //get
  const fetchPaymentsById = useQuery(
    ["payments", paymentId],
    () => getFinancePaymentsById(paymentId),
    {
      enabled: paymentId ? true : false,
      onSuccess: (data) => {
        console.log("payments detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/payments" }}>
              Payments
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchPaymentsById.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
        {!fetchPaymentsById.isLoading && (
          <>
            {" "}
            <PaymentsDetailModule paymentData={fetchPaymentsById?.data?.data} />
          </>
        )}
      </Col>
    </>
  );
}

export default PaymentDetailsPage;
