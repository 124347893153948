import React from "react";
import { Badge, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Check2Circle,
  CloudCheck,
  CloudCheckFill,
  XCircleFill,
  PencilSquare,
  Trash
} from "react-bootstrap-icons";

function UsersTableList({
  tableData = [],
  handleEditTableRow,
  handleDeleteTableRow,
  isLoading
}) {
  //
  return (
    <div>
      {isLoading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td>
              <small>User Name</small>
            </td>
            <td>
              <small>Email</small>
            </td>
            <td>
              <small>First Name</small>
            </td>
            <td>
              <small>Last Name</small>
            </td>
            <td>
              <small>Status</small>
            </td>
            <td>
              <small>Staff</small>
            </td>

            {(typeof handleEditTableRow === "function" ||
              typeof handleDeleteTableRow === "function") && (
              <td>
                <small>Actions</small>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {!tableData || tableData?.data?.data?.results.length < 1 ? (
            <tr>
              <td colSpan="7">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display{" "}
                  </strong>
                </center>
              </td>
            </tr>
          ) : (
            tableData?.data?.data?.results.map((data, index) => (
              <tr key={index}>
                <th>
                  {" "}
                  <Link
                    // to={"/accounts/profile" + item.id}
                    to="/accounts/profile"
                    state={{ userId: data?.id }}
                    className="text-decoration-none"
                  >
                    {data?.username}
                  </Link>
                </th>
                <td>{data?.email}</td>

                <td>{data?.first_name}</td>

                <td>{data?.last_name}</td>
                <td>
                  {data?.is_active ? (
                    <Badge bg="success">Active</Badge>
                  ) : (
                    <Badge bg="danger">Deactive</Badge>
                  )}
                </td>
                <td>
                  {data?.is_staff ? (
                    <Check2Circle color="green" />
                  ) : (
                    <XCircleFill color="red" />
                  )}
                </td>
                <td>
                  {typeof handleEditTableRow === "function" && (
                    <PencilSquare
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                        color: isLoading ? "gray" : "blue",
                        opacity: isLoading ? 0.5 : 1,
                        marginRight: "1rem"
                      }}
                      onClick={
                        isLoading ? null : () => handleEditTableRow(data)
                      }
                    />
                  )}

                  {typeof handleDeleteTableRow === "function" && (
                    <Trash
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                        color: isLoading ? "gray" : "red",
                        opacity: isLoading ? 0.5 : 1
                      }}
                      onClick={
                        isLoading ? null : () => handleDeleteTableRow(data)
                      }
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default UsersTableList;
