import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import moment from "moment";
import { formatShortDate } from "../../../utils/helper_functions";
// const keywordsData = {
//   id: 0,
//   keyword: "string",
//   category: "realtime",
//   shortcode: "string",
//   price: "930410",
//   label: "string",
//   code: "stri",
//   rate: "-.39",
//   mode: "latest",
//   auto_reply: true,
//   is_active: true,
//   message: "string",
//   updated: "2023-08-22T15:15:26.063Z",
//   created: "2023-08-22T15:15:26.063Z",
//   parent: 0,
//   owner: 0,
//   author: 0
// };

function KeywordsDetailModule({ keywordsData }) {
  console.log("keywordsData : ", keywordsData);
  return (
    <Container className="mt-5">
      {/* <h2>Keywords Details</h2> */}
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Keywords Details</Card.Header>
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">ID</th>
                        <td>{keywordsData?.id}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Keyword</th>
                        <td>{keywordsData?.keyword}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Category</th>
                        <td>{keywordsData?.category}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Shortcode</th>
                        <td>{keywordsData?.shortcode}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Price</th>
                        <td>{keywordsData?.price}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Label</th>
                        <td>{keywordsData?.label}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Code</th>
                        <td>{keywordsData?.code}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Rate</th>
                        <td>{keywordsData?.rate}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Mode</th>
                        <td>{keywordsData?.mode}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Auto Reply</th>
                        <td>{keywordsData?.auto_reply ? "Yes" : "No"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Active</th>
                        <td>{keywordsData?.is_active ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Message</th>
                        <td>{keywordsData?.message}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Created At</th>

                        <td>{formatShortDate(keywordsData?.created)}</td>
                        {/* <td>
                          {moment(keywordsData?.created).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td> */}
                      </tr>
                      <tr>
                        <th className="text-nowrap">Updated At</th>
                        <td>{formatShortDate(keywordsData?.updated)}</td>
                        {/* <td>
                          {moment(keywordsData?.updated).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td> */}
                      </tr>
                      <tr>
                        <th className="text-nowrap">Parent</th>
                        <td>{keywordsData?.parent}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={4}>
                  <Table>
                    <tbody>
                      <tr>
                        <th className="text-nowrap">Author</th>
                        <td>{keywordsData?.author}</td>
                      </tr>
                      <tr>
                        <th className="text-nowrap">Owner</th>
                        <td>{keywordsData?.owner}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default KeywordsDetailModule;
