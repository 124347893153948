import React from "react";
import WalletsPocketDetailModule from "./widgets/WalletsPocketDetailModule";
import {
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
  Container
} from "react-bootstrap";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getAllFinancePocket,
  getAllFinancePocketById,
  getAllFinancePocketByIdMinStatement,
  getAllFinancePocketPrimary
} from "../../services/finance/pockets-service.js";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

function WalletPocketDetailsPage() {
  //
  const { state } = useLocation();
  let walletPocketId = state?.walletPocketId;
  console.log("walletPocketId : ", walletPocketId);

  //
  //get
  const fetchWalletPocketByIdQuery = useQuery(
    ["pocket", walletPocketId],
    () => getAllFinancePocketById(walletPocketId),
    {
      enabled: walletPocketId ? true : false,
      onSuccess: (data) => {
        console.log("walletPocketId detail : ", data);
      },
      onError: (error, variables, context) => {
        console.log("error is : ", error);
      }
    }
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pt-1">
          <Breadcrumb className="mb-1">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/wallet" }}>
              wallet
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Col>
        {fetchWalletPocketByIdQuery.isLoading && (
          <center>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}

        {!fetchWalletPocketByIdQuery.isLoading && (
          <>
            <WalletsPocketDetailModule
              poketsDataQuery={fetchWalletPocketByIdQuery}
            />

          </>
        )}
      </Col>
    </>
  );
}

export default WalletPocketDetailsPage;
