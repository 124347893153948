import React, { useState } from "react";
import { Badge, Table, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatNumber, formatShortDate, shortText } from "../../../utils/helper_functions";
import { getAllMessages } from "../../../services/content/messages-service";
import Pager from "../../common/Pager";
import { useQuery } from "@tanstack/react-query";

function MessagesTableList(props) {
  const [page, setPage] = useState(1)
  
  const { isLoading, isError, error, data:messages, isFetching, isPreviousData } =
  useQuery({
    queryKey: ["messages", page,{...props.filter}],
    queryFn: () => getAllMessages({page:page, ...props.filter}),
    keepPreviousData: false
  });
  const setCurrentPage = (e, currentPage) =>{
      setPage(prevState => currentPage)
  }
  return (
    <div>
      {isLoading ? (
        <center><Spinner /></center>
          ) : isError ? (
            <Alert variant="danger">Error: {error.message}</Alert>
          ) : (<>
          <p className="pull-end text-right text-end">Showing {50*page} of {formatNumber(messages.data.count)} results</p>
      <Table
        hover
        responsive
        striped
        style={{ opacity: isLoading ? 0.5 : 1, transition: "opacity 0.2s" }}
      >
        <thead>
          <tr>
            <td className="d-none d-md-table-cell">ID </td>
            <td>Phone  </td>
            <td className="d-none d-md-table-cell">Message </td>
            <td className="d-none d-md-table-cell">Keyword</td>
            {/* <td>
              <small>Expiry</small>
            </td> */}
            <td>Status </td>
      
            <td className="d-none d-md-table-cell">Created At</td>

          </tr>
        </thead>
        <tbody>
          {messages.data?.results.length < 1 ? (
            <tr>
              <td colSpan="8">
                <center>
                  <strong style={{ color: "orange" }}>
                    No Records To Display {" "}
                  </strong>
                </center>
              </td>
            </tr>
          ) : (
            messages.data?.results.map((data, index) => (
              <tr key={index}>
                <td className="d-none d-md-table-cell">
                  {" "}
                  <Link
                    to="/messages/detail"
                    state={{ messageId: data?.id }}
                    className="text-decoration-none"
                  >
                    {data?.id}
                  </Link>
                </td>

                <td><Link
                    to="/messages/detail"
                    state={{ messageId: data?.id }}
                    className="text-decoration-none"
                  >{data?.phone}</Link><br/>
                <small className='d-md-none'>{shortText(data?.message,50)}</small><br/>
                <small className='d-md-none'>{formatShortDate(data?.created)}</small><br/>
                </td>
                <td className="d-none d-md-table-cell">{shortText(data?.message,50)}</td>
                <td className="d-none d-md-table-cell">{data?.keyword}</td>
                <td>{data?.status =='Sent' ? (
                    <Badge bg="success">Sent</Badge>
                  ) : (
                    <Badge bg="secondary">{data?.status}</Badge>
                  )}</td>
                
                <td className="d-none d-md-table-cell">{formatShortDate(data?.created)}</td>

              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Pager numberOfPages={Math.floor(messages?.data.count/50)} activePage={page} goToPage={setCurrentPage} />
      </>)}
    </div>
  );
}

export default MessagesTableList;
