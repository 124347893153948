import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopNavBar from "./TopNavBar";

import useAuthContext from "../../context/AuthContext";

function UserLayout({ children }) {
  const {
    getUserLoggedInUserDataQuery,
    setUserId,
    authUserProfile,
    setAuthUserProfile
  } = useAuthContext();
  return (
    <div>
      <TopNavBar authUserProfile={authUserProfile} />
      <Container>
        <Row className="g-3">
          <Col xs={12} className="pt-3">
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserLayout;
